import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  chakra,
  Flex,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { User } from '../models/User';
import { ProfessionalUser } from '../models/ProfessionalUser';

type ProfileActivationControlProps = {
  togglevalue: boolean;
  handleOnChange: (fieldname: string, newvalue: boolean) => void;
  handleAutoSave: (fieldname: string, newvalue: boolean) => void;
  userdata: User;
  prouser: ProfessionalUser;
};

function ProfileActivationControl({ togglevalue, handleOnChange, handleAutoSave, userdata, prouser, ...rest }: ProfileActivationControlProps) {
  const cancelRef = React.useRef<any>();
  const [activationValidationErrors, setActivationValidationErrors] = useState<string[] | undefined>();
  const [showActivationErrors, setShowActivationErrors] = useState(false);

  const validateProfileActivation = () => {
    const validationErrors = [];
    if (!userdata.email) validationErrors.push('Missing Email');
    if (!userdata.address || !userdata.address?.line1 || !userdata.address.city || !userdata.address.stateCode || !userdata.address.zipCode)
      validationErrors.push('Missing Address Info');
    if (!userdata.birthday) validationErrors.push('Missing Date of Birth');
    if (!prouser.tagline) validationErrors.push('Missing Profile tagline');
    if (!prouser.bio) validationErrors.push('Missing Profile bio');
    if (!prouser.serviceDetails || prouser.serviceDetails.length === 0) validationErrors.push('Must enter at least one service');
    if (!prouser.connectedAccount || prouser.connectedAccount.status !== 'details_submitted') validationErrors.push('Must complete Stripe setup');
    if (prouser.onboardingRequirements?.availabilityRequired) validationErrors.push('Must complete availability onboarding task');
    if (prouser.onboardingRequirements?.profileRequired) validationErrors.push('Must complete profile onboarding task');
    if (prouser.onboardingRequirements?.servicesRequired) validationErrors.push('Must complete services onboarding task');

    if (validationErrors.length > 0) {
      setActivationValidationErrors(validationErrors);
      setShowActivationErrors(true);
      return false;
    }
    setActivationValidationErrors(undefined);
    setShowActivationErrors(false);
    return true;
  };

  return (
    <>
      <Flex flex={1} flexDir="column" mr={2} {...rest}>
        <Text fontSize="xs">Profile Visibility</Text>
        <Flex mt={2}>
          <Text size="sm" color="orange.400" mr={4}>
            {togglevalue ? 'Active' : 'Inactive'}
          </Text>
          <Switch
            size="md"
            colorScheme="orange"
            isChecked={togglevalue}
            onChange={(e) => {
              if (e.target.checked) {
                // const check = validateProfileActivation();
                const check = true;
                if (check) {
                  const newvalue = !prouser.visible_to_clients;
                  console.log('pac newvalue: ', newvalue);
                  handleOnChange('visible_to_clients', newvalue);
                  handleAutoSave('visible_to_clients', newvalue);
                }
              } else {
                const newvalue = !prouser.visible_to_clients;
                handleOnChange('visible_to_clients', newvalue);
                handleAutoSave('visible_to_clients', newvalue);
              }
            }}
          />
        </Flex>
      </Flex>
      {activationValidationErrors && activationValidationErrors.length > 0 && (
        <AlertDialog leastDestructiveRef={cancelRef} isOpen={showActivationErrors} size="xl" isCentered onClose={() => setShowActivationErrors(false)}>
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader fontWeight="bold" color="red.700">
              Missing Required Info
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody p={6}>
              <Textarea resize="vertical" height="md">
                {activationValidationErrors.join(',\n')}
              </Textarea>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  );
}

export default chakra(ProfileActivationControl);
