/* eslint-disable no-console */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  Center,
  Heading,
  FormControl,
  Input,
  Flex,
  InputGroup,
  Text,
  Button,
  Divider,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  FormErrorMessage,
  Box,
  Icon,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { FaApple } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { auth, logInWithEmailAndPassword, signInWithGoogle, signInWithApple, sendPasswordReset } from '../config/firebase';
import { SUCCESS } from '../config/LoginConstants';

function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pwVisibility, setPwVisibility] = useState(false);
  const [showPwRecover, setShowPwRecover] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [loginError, setLoginError] = useState('this is an error');
  const toast = useToast();
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      console.log(user);
      // navigate('/home');
    }
  }, [user, loading]);

  const onClickContinueWithEmail = () => {
    if (email && password) {
      logInWithEmailAndPassword(email, password).then((response) => {
        if (response !== SUCCESS) {
          setInvalid(true);
          setLoginError(response);
        }
      });
    } else {
      setInvalid(true);
      setLoginError('Please enter a valid email address and password.');
    }
  };

  const onClickSignInWithGoogle = () => {
    signInWithGoogle().then((response) => {
      if (response !== SUCCESS) {
        setInvalid(true);
        setLoginError(response);
      }
    });
  };

  const onClickSignInWithApple = () => {
    signInWithApple().then((response) => {
      if (response !== SUCCESS) {
        setInvalid(true);
        setLoginError(response);
      }
    });
  };

  return (
    <Flex>
      <Box w={['100%', '100%', '100%', '50%', '40%']} position="relative">
        <Flex flexDir="column" justifyContent="center" alignItems="center" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
          <Heading flex={1} fontFamily="benibold" fontWeight="700" fontSize="40px" my={4} letterSpacing={0.6} color="charcoal">
            Sign in to Donne
          </Heading>
          <FormControl isRequired isInvalid={invalid}>
            <FormErrorMessage justifyContent="center" textAlign="center" my={0} lineHeight={1.5}>
              {loginError}
            </FormErrorMessage>
            <InputGroup>
              <Input
                placeholder="Email"
                autoCapitalize="none"
                type="email"
                autoFocus
                m={2}
                pr={12}
                value={email}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <Input
                placeholder="Password"
                autoCapitalize="none"
                type={pwVisibility ? 'text' : 'password'}
                m={2}
                pr={12}
                value={password}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
              />
              <InputRightElement>
                {pwVisibility ? (
                  <ViewIcon m={0} pt={4} pr={2} h={8} w={8} onClick={() => setPwVisibility(!pwVisibility)} />
                ) : (
                  <ViewOffIcon m={0} pt={4} pr={2} h={8} w={8} onClick={() => setPwVisibility(!pwVisibility)} />
                )}
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Text
            as="u"
            color="orange.400"
            cursor="pointer"
            mt={1}
            mb={3}
            onClick={() => {
              setShowPwRecover(true);
              toast({
                title: 'Reset requested',
                description: 'Please check your email for instructions',
                status: 'info',
                duration: 5000,
                isClosable: true,
              });
            }}
          >
            Forgot Password?
          </Text>
          <Button w="75%" bg="blue.400" onClick={onClickContinueWithEmail}>
            Sign in with email
          </Button>

          <Flex flex={1} width="100%" mb={1} mt={2} flexDir="row" justifyContent="center" alignItems="center">
            <Divider bg="charcoal" width={36} borderWidth={1} />
            <Text as="b" my={2} mx={4} color="charcoal">
              or
            </Text>
            <Divider bg="charcoal" width={36} borderWidth={1} />
          </Flex>

          <Flex flexDir="column" flex={1} width="75%">
            <Button my={2} bg="black" color="white" _hover={{ bg: '#4E4E4E' }} onClick={onClickSignInWithApple} leftIcon={<Icon as={FaApple} />}>
              Sign in with Apple
            </Button>
            <Button variant="outline" my={2} onClick={onClickSignInWithGoogle} leftIcon={<Icon as={FcGoogle} />}>
              Sign in with Google
            </Button>
          </Flex>
          <Divider my={6} mb={4} bg="muted.400" borderWidth={1} />

          <Center>
            <Text fontWeight="bold" color="orange.400" fontSize="16px">
              Want to be a Donne Professional?
            </Text>
          </Center>
          <Button variant="outline" mt={2} width="75%" onClick={() => navigate('/application')}>
            Apply to join
          </Button>
          {message.length > 0 && <Text>{message}</Text>}
        </Flex>
      </Box>
      <Box w={['0%', '0%', '0%', '50%', '60%']} visibility={['hidden', 'hidden', 'hidden', 'visible', 'visible']} h="100vh" bg="blue.400" position="relative">
        <Flex flexDir="column" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
          <Center>
            <svg width="341" height="341" viewBox="0 0 341 341" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M176.955 154.365L168.558 154.351L168.507 186.253L176.902 186.266C182.778 186.276 188.497 180.745 188.515 170.333C188.532 159.924 182.831 154.374 176.955 154.365Z"
                fill="#EE5523"
              />
              <path
                d="M180.364 225.396L116.558 225.292L116.738 115.144L180.711 115.247C212.447 115.299 241.968 133.313 241.909 170.421C241.849 207.697 212.101 225.447 180.364 225.396ZM277.836 170.27L340.769 147.854L274.179 142.489L329.165 104.547L263.456 116.6L306.746 65.7224L246.397 94.372L275.047 34.0223L224.167 77.3132L236.222 11.6032L198.28 66.5901L192.914 0L170.499 62.9325L148.083 0L142.718 66.5901L104.777 11.6032L116.83 77.3132L65.9523 34.0223L94.602 94.372L34.2516 65.7224L77.5432 116.6L11.8332 104.547L66.8201 142.489L0.230957 147.854L63.163 170.27L0.230957 192.685L66.8201 198.05L11.8332 235.992L77.5432 223.938L34.2516 274.817L94.602 246.167L65.9523 306.517L116.83 263.225L104.777 328.935L142.718 273.949L148.083 340.539L170.499 277.606L192.914 340.539L198.28 273.949L236.222 328.935L224.167 263.225L275.047 306.517L246.397 246.167L306.746 274.817L263.456 223.938L329.165 235.992L274.179 198.05L340.769 192.685L277.836 170.27Z"
                fill="#EE5523"
              />
            </svg>
          </Center>
          <Center>
            <Text fontFamily="interbold" fontWeight="700" fontSize="40px" textAlign="center" color="#EE5523" whiteSpace="nowrap">
              always something to wear
            </Text>
          </Center>
        </Flex>
      </Box>
      <Modal size="sm" isCentered isOpen={showPwRecover} onClose={() => setShowPwRecover(false)}>
        <ModalOverlay />
        <ModalContent borderRadius="lg">
          <ModalHeader>Forgot Password?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" justifyContent="center" m={2}>
              <Text fontSize="sm">Email:</Text>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={4} onClick={() => setShowPwRecover(false)}>
              Cancel
            </Button>
            <Button
              variant="solid"
              onClick={() => {
                sendPasswordReset(email);
                setShowPwRecover(false);
                alert('Follow instructions in email to reset your password.');
              }}
            >
              Reset
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default LoginScreen;
