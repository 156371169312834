import { Flex, Avatar, Center, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useFilePicker } from 'use-file-picker';
import { User as UserAuth } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { uploadUserProfilePic } from '../../services/UsersService';
import EditIcon from '../../components/EditIcon';

type EditableProfileAvatarProps = {
  currentAvatarUrl: string;
  handleAvatarChanged: (newvalue: string) => void;
};

function EditableProfileAvatar({ currentAvatarUrl, handleAvatarChanged }: EditableProfileAvatarProps) {
  const [user] = useAuthState(auth);
  const [openFileSelector, { filesContent, plainFiles, loading, errors, clear: clearFilePicker }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*', '.png', '.jpg', 'jpeg'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 20,
    imageSizeRestrictions: {
      maxHeight: 2000, // in pixels
      maxWidth: 2000,
      minHeight: 20,
      minWidth: 20,
    },
  });

  const formattedErrors = () => {
    if (!errors || errors.length === 0) return '';
    let errmsg = 'Errors: ';
    errors.forEach((e) => {
      if (e.fileSizeToolarge) errmsg = 'Error: File is too large (> 20 MB).';
      if (e.imageHeightTooBig) errmsg = 'Error: Max height and width is 2000px';
      if (e.imageWidthTooBig) errmsg = 'Error: Max height and width is 2000px';
    });
    return errmsg;
  };

  return (
    <>
      <Flex position="relative">
        <Avatar src={currentAvatarUrl} size="2xl" mb={4} />
        <EditIcon onClick={openFileSelector} />
      </Flex>
      <Modal size="sm" isCentered isOpen={(plainFiles && plainFiles[0] !== undefined) || (errors && errors.length > 0)} onClose={clearFilePicker}>
        <ModalOverlay />
        <ModalContent borderRadius="lg">
          <ModalHeader>Upload Avatar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" justifyContent="center" alignItems="center">
              <Avatar src={filesContent[0]?.content} size="2xl" mb={4} />
            </Flex>
            <Center>
              <Text color="red.400">{formattedErrors()}</Text>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" flex={1}>
              <Button
                variant="darkoutline"
                onClick={() => {
                  clearFilePicker();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                onClick={() => {
                  uploadUserProfilePic(user as UserAuth, plainFiles[0])
                    .then((res) => res.json())
                    .then((r) => {
                      handleAvatarChanged(r);
                      clearFilePicker();
                    })
                    .catch((e) => console.log(e));
                }}
              >
                Save
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default EditableProfileAvatar;
