/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
import {
  Box,
  Text,
  Flex,
  Select,
  Button,
  Textarea,
  Input,
  Image,
  SimpleGrid,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Icon,
  useToast,
  HStack,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useFilePicker } from 'use-file-picker';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';
import { User } from '../../models/User';
import { auth } from '../../config/firebase';
import { CurrentUserContext } from '../../contexts/CurrentUserProvider';
import { CurrentProfessionalContext } from '../../contexts/CurrentProfessionalProvider';
import LoadingScreen from '../../components/LoadingScreen';
import { PageTabs } from '../../components/PageTabs';
import { PortfolioLink, ProfessionalUser, ServiceDetails } from '../../models/ProfessionalUser';
import { EXPERT_SERVICE_OFFERINGS, SERVICE_PRICING_OPTIONS } from '../../models/ExpertServiceOfferings';
import { fetchProfessionalDetails, updateProfessionalDetails, updateProfessionalDetailsWithFile } from '../../services/ProfessionalsService';
import UserPortfolioDisplay from '../../components/UserPortfolioDisplay';
import AddIcon from '../../components/AddIcon';
import EditableProfileAvatar from './EditableProfileAvatar';
import ProfessionalRatingSummary from './ProfessionalRatingSummary';
import PersonalInfo from './PersonalInfo';
import AccountProfile from './AccountProfile';
import { fetchUser, updateUser } from '../../services/UsersService';

function AccountScreen() {
  const location = useLocation();
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const [user] = useAuthState(auth);
  const { currentProfessional, setCurrentProfessional } = useContext(CurrentProfessionalContext);
  const toast = useToast();
  const [selectedTab, setSelectedTab] = useState(location.state?.tab || 'Personal Info');
  const [showServicesModal, setShowServicesModal] = useState(false);
  const [selectedService, setSelectedService] = useState<ServiceDetails | undefined>();
  const [userdata, setUserData] = useState<User>();
  const [prouser, setprouser] = useState<ProfessionalUser>();

  useEffect(() => {
    // console.log('Account.useEffect fetchUser');
    if (userdata) return;
    const fetch = async () => {
      await fetchUser(user as UserAuth, currentUser.userid)
        .then((res: { json: () => any }) => res.json())
        .then((result: User) => setUserData(result));
    };
    fetch().catch(console.error);
    // eslint-disable-next-line consistent-return
    return () => {
      console.log('unmounting and updating current user...');
      if (userdata) setCurrentUser(userdata);
    };
  }, []);

  useEffect(() => {
    if (prouser) return;
    const fetch = async () => {
      await fetchProfessionalDetails(user as UserAuth, currentUser.userid)
        .then((res: { json: () => any }) => res.json())
        .then((result: ProfessionalUser) => setprouser(result));
    };
    fetch().catch(console.error);
    // eslint-disable-next-line consistent-return
    return () => {
      console.log('unmounting and updating current pro...');
      if (prouser) setCurrentProfessional(prouser);
    };
  }, []);

  if (!currentProfessional || !currentUser) return <LoadingScreen />;
  if (!prouser || !userdata) return <LoadingScreen />;
  // const prouser = currentProfessional as ProfessionalUser;

  // This page needs to support editing fields that belong to both the core user entity and the professionaldetails entity.
  // Be careful because the ProfessionalUser model has fields from the User entity that should not be edited from the ProfDetail entity

  const updateUserDataField = (field: string, value: any) => {
    const clone = structuredClone(userdata);
    const pList = field.split('.');
    const key = pList.pop();
    const pointer = pList.reduce((accumulator, currentValue) => {
      if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
      return accumulator[currentValue];
    }, clone);
    if (key) pointer[key] = value;

    setUserData(clone);
  };

  const handleAutoSaveUserDataField = async (field: string, value: any) => {
    const clone = structuredClone(userdata);
    const pList = field.split('.');
    const key = pList.pop();
    const pointer = [...pList].reduce((accumulator, currentValue) => {
      if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
      return accumulator[currentValue];
    }, clone);

    const originalPointer = [...pList].reduce((accumulator, currentValue) => {
      if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
      return accumulator[currentValue];
    }, currentUser);

    if (key && pointer[key] === originalPointer[key]) return; // handles case where value did not actually change

    if (key) pointer[key] = value;

    await updateUser(user as UserAuth, clone)
      .then((result) => {
        setUserData(clone);
        // setCurrentUser(userdata); // update context  NOTE:  doing this rerenders entire page
        toast({
          title: 'Personal Info Saved',
          description: 'Data saved successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      })
      .catch((er) => console.log(er));
  };

  const handleSaveUserData = async () => {
    updateUser(user as UserAuth, userdata)
      .then((result) => {
        setCurrentUser(userdata); // update context
        toast({
          title: 'Personal Info Saved',
          description: 'Data saved successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      })
      .catch((er) => console.log(er));
  };

  const updateUserAvatarUrl = (url: string) => {
    updateUserDataField('useravatarurl', url);
    handleAutoSaveUserDataField('useravatarurl', url);
  };

  const updateProDataField = (field: string, value: any) => {
    const clone = structuredClone(prouser);
    const pList = field.split('.');
    const key = pList.pop();
    const pointer = pList.reduce((accumulator, currentValue) => {
      if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
      return accumulator[currentValue];
    }, clone);
    if (key) pointer[key] = value;
    setprouser(clone);
  };

  const handleAutoSaveProDataField = async (field: string, value: any) => {
    const clone = structuredClone(prouser);
    const pList = field.split('.');
    const key = pList.pop();
    const pointer = pList.reduce((accumulator, currentValue) => {
      if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
      return accumulator[currentValue];
    }, clone);

    const originalPointer = [...pList].reduce((accumulator, currentValue) => {
      if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
      return accumulator[currentValue];
    }, currentProfessional);

    if (key && key !== 'visible_to_clients' && pointer[key] === originalPointer[key]) return; // handles case where value did not actually change

    if (key) pointer[key] = value;

    await updateProfessionalDetails(user as UserAuth, clone as ProfessionalUser)
      .then((result) => {
        if (result.ok) {
          setprouser(clone);
          if (key === 'visible_to_clients') setCurrentProfessional(clone);
          toast({
            title: 'Profile Saved',
            description: 'Data saved successfully',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
        } else {
          console.log('error: ', result);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleSaveProfessionalDetails = async () => {
    updateProfessionalDetails(user as UserAuth, prouser as ProfessionalUser).then((result) => {
      if (result.ok) {
        setCurrentProfessional(prouser);
        toast({
          title: 'Profile Saved',
          description: 'Data saved successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      }
    });
  };

  const handleSaveService = async (professional: ProfessionalUser) => {
    updateProfessionalDetails(user as UserAuth, professional as ProfessionalUser).then((result) => {
      if (result.ok) {
        toast({
          title: 'Service Saved',
          description: 'Data saved successfully',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      }
    });
  };

  const renderPersonalInfoTab = () => (
    <PersonalInfo
      userdata={userdata}
      setUserData={setUserData}
      updateUserDataFieldHandler={updateUserDataField}
      autoSaveUserDataFieldHandler={handleAutoSaveUserDataField}
      saveUserDataHandler={handleSaveUserData}
      updateUserAvatarUrl={updateUserAvatarUrl}
    />
  );

  const renderProfileTab = () => (
    <AccountProfile
      userdata={userdata}
      setUserData={setUserData}
      prouser={prouser}
      setprouser={setprouser}
      updateUserDataFieldHandler={updateUserDataField}
      autoSaveUserDataFieldHandler={handleAutoSaveUserDataField}
      saveUserDataHandler={handleSaveUserData}
      updateProDataFieldHandler={updateProDataField}
      autoSaveProDataFieldHandler={handleAutoSaveProDataField}
      saveProDataHandler={handleSaveProfessionalDetails}
      updateUserAvatarUrl={updateUserAvatarUrl}
    />
  );

  const renderPortfolioTab = () => (
    <UserPortfolioDisplay
      handleUpdateCompleted={() => {
        // setprouser(currentProfessional);
        console.log('handleUpdateComplted.currentProfessional: ', currentProfessional);
      }}
      handleUpdateProUser={(pu) => {
        setprouser(pu);
        console.log('handleUpdateProUser.pu: ', pu);
      }}
    />
  );

  const renderServicesTab = () => (
    <Flex flex={1} p={12} width="100%">
      <Flex flex={0.2} flexDir="column" alignItems="center" mx={4}>
        <EditableProfileAvatar currentAvatarUrl={userdata.useravatarurl} handleAvatarChanged={updateUserAvatarUrl} />
        <ProfessionalRatingSummary rating={prouser.rating} reviews={prouser.reviewdetails} />
      </Flex>
      <Flex flex={0.8} flexDir="column" mx={4}>
        <Flex alignItems="center" mb={2}>
          <Text fontSize="18" fontWeight="bold" mr={2}>
            Services
          </Text>
          <Box
            p={2}
            onClick={() => {
              setSelectedService(undefined);
              setShowServicesModal(true);
            }}
          >
            <AddIcon />
          </Box>
        </Flex>
        <Flex flex={1}>
          <SimpleGrid minChildWidth={80} spacing={8} flex={1}>
            {prouser.serviceDetails.map((service, i) => {
              const iconurl = EXPERT_SERVICE_OFFERINGS.filter((s) => s.value === service.serviceName)[0]?.thumbnailUri;
              const pricetext = `$${service.pricepoint} ${service.pricetype}`;
              let serviceDescription = service.description;
              if (!serviceDescription) serviceDescription = EXPERT_SERVICE_OFFERINGS.filter((so) => so.value === service.serviceName)[0].defaultDescription;
              if (!service.pricetype) service.pricetype = 'hour';
              // console.log('service: ', service);
              const needsAttention: boolean = !service.pricepoint || !service.pricetype || !service.turnaroundTimeInDays;
              return (
                <Flex key={i.toString()} flex={1} py={2} px={2} borderWidth={1} alignItems="center">
                  <Image src={iconurl} ml={1} mr={2} fit="contain" />
                  <Flex flex={5} flexDir="column" mx={2}>
                    <Text fontSize="sm" fontWeight="bold">
                      {service.serviceName}
                    </Text>
                    {!needsAttention && <Text fontSize="sm">{pricetext}</Text>}
                    {needsAttention && (
                      <HStack>
                        <WarningTwoIcon color="yellow.400" />
                        <Text>Action Required: Complete Details</Text>
                      </HStack>
                    )}
                    {service.isInactive && (
                      <Text fontWeight="bold" color="grey.700" p={1} bg="yellow.400">
                        INACTIVE
                      </Text>
                    )}
                  </Flex>
                  <Flex flex={1}>
                    <Popover placement="bottom-end">
                      <PopoverTrigger>
                        <Text pl="2" fontWeight="bold" fontSize="lg" lineHeight={1} justifyContent="center" alignItems="center">
                          ...
                        </Text>
                      </PopoverTrigger>
                      <PopoverContent bg="white" borderWidth={1} borderColor="black" mt={2} maxWidth="14rem" _focusVisible={{ boxShadow: undefined }}>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Flex flexDir="column" m={1}>
                            <Text
                              my={1}
                              p={1}
                              onClick={() => {
                                setSelectedService(service);
                                setShowServicesModal(true);
                              }}
                            >
                              Edit Service
                            </Text>
                            <Text
                              my={1}
                              p={1}
                              onClick={() => {
                                const clone: ProfessionalUser = structuredClone(prouser);
                                const serviceToDeactivate = clone.serviceDetails.filter((l) => l.serviceName === service.serviceName)[0];
                                if (serviceToDeactivate) {
                                  serviceToDeactivate.isInactive = !serviceToDeactivate.isInactive;
                                  setprouser(clone);
                                  handleSaveService(clone);
                                }
                              }}
                            >
                              Set as {service.isInactive ? 'Active' : 'Inactive'}
                            </Text>
                            <Text
                              my={1}
                              p={1}
                              onClick={() => {
                                const clone: ProfessionalUser = structuredClone(prouser);
                                clone.serviceDetails = clone.serviceDetails.filter((sd) => sd.serviceName !== service.serviceName);
                                setprouser(clone);
                                handleSaveService(clone);
                              }}
                            >
                              Remove Service
                            </Text>
                          </Flex>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                  <Modal isOpen={showServicesModal} onClose={() => setShowServicesModal(false)} size="3xl">
                    <ModalOverlay bg="rgba(0,0,0,.1)" />
                    <ModalContent>
                      <ModalHeader py={2}>{selectedService ? 'Edit Service' : 'Add New Service'}</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody pb={6} display="flex" flex={1} flexDir="column">
                        <Flex width="70%" flexDir="column">
                          <FormControl isRequired>
                            <FormLabel>Service Category</FormLabel>
                            <Select
                              value={selectedService?.serviceName}
                              variant="outline"
                              borderColor="charcoal"
                              onChange={(e) =>
                                setSelectedService({
                                  ...selectedService,
                                  serviceName: e.target.value,
                                } as ServiceDetails)
                              }
                            >
                              {EXPERT_SERVICE_OFFERINGS.filter((so) => so.value !== 'All').map((so) => (
                                <option key={so.value} value={so.value}>
                                  {so.title}
                                </option>
                              ))}
                            </Select>
                            {/* <Flex alignItems="center" px={1}>
                              <InfoOutlineIcon color="blue.400" mr={2} />
                              <Link href="/" size="sm" color="blue.400">
                                TODO: See category descriptions &gt;
                              </Link>
                            </Flex> */}
                          </FormControl>
                          <FormControl isRequired mt={4}>
                            <FormLabel>Service Description</FormLabel>
                            <Textarea
                              value={selectedService?.description}
                              onChange={(e) =>
                                setSelectedService({
                                  ...selectedService,
                                  description: e.target.value,
                                } as ServiceDetails)
                              }
                            />
                          </FormControl>
                        </Flex>
                        <Flex flex={1} my={4} alignItems="flex-end">
                          <Flex flex={1}>
                            <FormControl isRequired mt={4}>
                              <FormLabel mb={0}>Cost Per Service</FormLabel>
                              <FormLabel fontSize="xs" m={0} p={0}>
                                Cost you will charge for this service
                              </FormLabel>
                              <Flex alignItems="center" mt={2}>
                                <Text pr={0.5}>$</Text>
                                <Input
                                  width="40%"
                                  mr={2}
                                  borderWidth={1}
                                  borderColor="charcoal"
                                  type="number"
                                  value={selectedService?.pricepoint}
                                  onChange={(e) =>
                                    setSelectedService({
                                      ...selectedService,
                                      pricepoint: e.target.valueAsNumber,
                                    } as unknown as ServiceDetails)
                                  }
                                />
                                <Select
                                  variant="outline"
                                  maxWidth="50%"
                                  value={selectedService?.pricetype}
                                  onChange={(e) =>
                                    setSelectedService({
                                      ...selectedService,
                                      pricetype: e.target.value,
                                    } as ServiceDetails)
                                  }
                                >
                                  {SERVICE_PRICING_OPTIONS.map((so) => (
                                    <option key={so.value} value={so.value}>
                                      {so.name}
                                    </option>
                                  ))}
                                </Select>
                              </Flex>
                            </FormControl>
                          </Flex>
                          <Flex flex={1}>
                            <FormControl isRequired>
                              <FormLabel mb={0}>Estimated Turnaround Time</FormLabel>
                              <FormLabel fontSize="xs" m={0} p={0}>
                                Average time (in days) it takes you to complete this service
                              </FormLabel>
                              <Flex alignItems="center" mt={2}>
                                <Input
                                  width="40%"
                                  borderColor="charcoal"
                                  mr={2}
                                  type="number"
                                  value={selectedService?.turnaroundTimeInDays}
                                  onChange={(e) => {
                                    setSelectedService({
                                      ...selectedService,
                                      turnaroundTimeInDays: e.target.valueAsNumber as unknown as number,
                                    } as ServiceDetails);
                                  }}
                                />
                                <Text> days</Text>
                              </Flex>
                            </FormControl>
                          </Flex>
                          <Flex flex={1}>
                            <FormControl isRequired>
                              <FormLabel mb={0}>Estimated Service Charge</FormLabel>
                              <FormLabel fontSize="xs" m={0} p={0}>
                                Average total charge for this service
                              </FormLabel>
                              <Flex alignItems="center" mt={2}>
                                <Text pr={0.5}>$</Text>
                                <Input
                                  width="40%"
                                  borderColor="charcoal"
                                  mr={2}
                                  type="number"
                                  value={selectedService?.averagevalue}
                                  onChange={(e) => {
                                    setSelectedService({
                                      ...selectedService,
                                      averagevalue: e.target.valueAsNumber as unknown as number,
                                    } as ServiceDetails);
                                  }}
                                />
                                <Text>total</Text>
                              </Flex>
                            </FormControl>
                          </Flex>
                        </Flex>
                      </ModalBody>
                      <ModalFooter px={4}>
                        <Button
                          variant="darkoutline"
                          mr={3}
                          onClick={() => {
                            setSelectedService(undefined);
                            setShowServicesModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="solid"
                          mr={3}
                          onClick={() => {
                            // TODO: refactor all this
                            // TODO: unhack these 2 sets:
                            if (selectedService && !selectedService.pricetype) selectedService.pricetype = 'hour';
                            if (selectedService && !selectedService.serviceName) selectedService.serviceName = EXPERT_SERVICE_OFFERINGS[1].value;

                            if (
                              !selectedService ||
                              !selectedService.serviceName ||
                              !selectedService.description ||
                              !selectedService.pricepoint ||
                              !selectedService.turnaroundTimeInDays ||
                              !selectedService.averagevalue
                            ) {
                              toast({
                                title: 'Missing Required Info',
                                description: 'Please enter all required fields',
                                status: 'error',
                                duration: 3000,
                                isClosable: true,
                              });
                              return;
                            }
                            const clone: ProfessionalUser = structuredClone(prouser);
                            const existing = clone.serviceDetails.filter((l) => l.serviceName === selectedService?.serviceName);
                            if (existing && existing[0] && selectedService) {
                              // this is an update:
                              existing[0].serviceName = selectedService.serviceName;
                              existing[0].description = selectedService.description;
                              existing[0].pricepoint = selectedService.pricepoint;
                              existing[0].pricetype = selectedService.pricetype;
                              existing[0].averagevalue = selectedService.averagevalue;
                              existing[0].turnaroundTimeInDays = selectedService.turnaroundTimeInDays;
                            } else {
                              // this is a new:
                              if (!clone.serviceDetails) clone.serviceDetails = [];
                              clone.serviceDetails.push(selectedService as ServiceDetails);
                            }
                            setprouser(clone);
                            handleSaveService(clone);
                            setSelectedService(undefined);
                            setShowServicesModal(false);
                          }}
                        >
                          Save Service
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Flex>
              );
            })}
          </SimpleGrid>
        </Flex>
        <Flex mt={8} width="90%" justifyContent="space-between">
          <Button variant="darkoutline" onClick={() => setprouser(currentProfessional)}>
            Cancel
          </Button>
          <Button
            variant="solid"
            onClick={() => {
              handleSaveProfessionalDetails();
            }}
          >
            Save Services
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );

  return (
    <Flex flex={1} width="100%" flexDir="column" alignItems="center" px={12} py={6}>
      <Flex flex={1} width="100%" flexDir="column" alignItems="center" borderWidth={1} borderRadius="md" my={0}>
        <PageTabs tabNames={['Personal Info', 'Profile', 'Portfolio', 'Services']} selectedTab={selectedTab} handleTabSelected={setSelectedTab} />
        {selectedTab === 'Personal Info' && renderPersonalInfoTab()}
        {selectedTab === 'Profile' && renderProfileTab()}
        {selectedTab === 'Portfolio' && renderPortfolioTab()}
        {selectedTab === 'Services' && renderServicesTab()}
      </Flex>
    </Flex>
  );
}

export default AccountScreen;
