import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Box, Flex, Slide, VStack, Button } from '@chakra-ui/react';
import { auth } from './config/firebase';
import { CurrentUserContext } from './contexts/CurrentUserProvider';
import Header from './components/Header';
import LeftNav from './components/LeftNav';
import AvailabilityScreen from './screens/Availability';
import ClientDetailsScreen from './screens/ClientDetails';
import ClientsScreen from './screens/Clients';
import ClientWardrobeScreen from './screens/ClientWardrobe';
import HomeScreen from './screens/Home';
import LoginScreen from './screens/Login';
import MessagesScreen from './screens/MessagesScreen';
import RequestsScreen from './screens/Requests';
import AccountScreen from './screens/profile/Account';
import ResourcesScreen from './screens/Resources';
import DashboardScreen from './screens/Dashboard';
import ApplicationScreen from './screens/Application';
import InvoiceScreen from './screens/Invoice';

function PageLayout() {
  const [user, loading, error] = useAuthState(auth);
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const location = useLocation();
  const pathsThatHideLefNav = ['/clientwardrobe', '/messages', '/clientdetails', '/application', '/invoice'];
  const [hideLeftNav, setHideLeftNav] = useState(pathsThatHideLefNav.includes(location.pathname));

  useEffect(() => {
    setHideLeftNav(pathsThatHideLefNav.includes(location.pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Header handleHamburgerToggle={() => setHideLeftNav(!hideLeftNav)} />
      <Flex flex={1} height="100%" flexDirection="row">
        {!hideLeftNav && (
          <Flex flex={0.1} h="100%" backgroundColor="blue.400">
            <LeftNav />
          </Flex>
        )}
        <Flex flex={hideLeftNav ? 1 : 0.9} alignItems="flex-start">
          <Routes>
            {user ? (
              <>
                {currentUser?.isProfessional && (
                  <>
                    <Route path="/home" element={<HomeScreen />} />
                    <Route path="/login" element={<LoginScreen />} />
                    <Route path="/messages" element={<MessagesScreen />} />
                    <Route path="/requests" element={<RequestsScreen />} />
                    <Route path="/availablity" element={<AvailabilityScreen />} />
                    <Route path="/clients" element={<ClientsScreen />} />
                    <Route path="/clientdetails" element={<ClientDetailsScreen />} />
                    <Route path="/clientwardrobe" element={<ClientWardrobeScreen />} />
                    <Route path="/invoice" element={<InvoiceScreen />} />
                    <Route path="/account" element={<AccountScreen />} />
                    <Route path="/resources" element={<ResourcesScreen />} />
                    <Route path="/dashboard" element={<DashboardScreen />} />
                    <Route path="/application" element={<ApplicationScreen />} />
                    <Route path="/" element={<HomeScreen />} />
                  </>
                )}
                {currentUser && !currentUser.isProfessional && (
                  <>
                    <Route path="/login" element={<LoginScreen />} />
                    <Route path="/application" element={<ApplicationScreen />} />
                    <Route path="/" element={<LoginScreen />} />
                  </>
                )}
              </>
            ) : (
              <>
                {/* <Route path='/home' element={<HomeScreen />} /> */}
                <Route path="/login" element={<LoginScreen />} />
              </>
            )}
          </Routes>
        </Flex>
      </Flex>
    </>
  );
}

export default PageLayout;
