import { Icon } from '@chakra-ui/react';
import React from 'react';
import { PiPencilSimpleBold } from 'react-icons/pi';

type EditIconProps = {
  onClick: () => void;
};

function EditIcon({ onClick }: EditIconProps) {
  return (
    <Icon
      as={PiPencilSimpleBold}
      boxSize={7}
      color="white"
      position="absolute"
      bottom={29}
      right={2}
      p="7px"
      borderRadius="100px"
      bg="orange.400"
      onClick={onClick}
      cursor="pointer"
    />
  );
}

export default EditIcon;
