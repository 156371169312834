import moment from 'moment';
import {
  Box,
  Text,
  Heading,
  Button,
  Flex,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Avatar,
  HStack,
  Tabs,
  TabList,
  Tab,
  Spinner,
  DrawerFooter,
  Textarea,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useMemo, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { createColumnHelper } from '@tanstack/react-table';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import { CompleteEngagementDTO, ProfessionalEngagementRequest } from '../models/ProfessionalEngagementRequest';
import {
  completeProfessionalEngagement,
  fetchProfessionalEngagementRequests,
  sendAcceptProfessionalEngagementRequest,
  sendDeclineProfessionalEngagementRequest,
  updateEngagementRequest,
} from '../services/EngagementsService';
import { DataTable, userTableCellDisplayData } from '../components/DataTable';
import { fetchUser } from '../services/UsersService';
import LabelValueDisplayItem from '../components/LabelValueDisplayItem';
import ClientDetailsDisplay from '../components/ClientDetailsDisplay';

function RequestsScreen() {
  const [engagementRequests, setEngagementRequests] = useState<ProfessionalEngagementRequest[]>([]);
  const [user] = useAuthState(auth);
  const { isOpen, onOpen, onClose } = useDisclosure(); // controls side drawer for request details
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [messageToRequestor, setMessageToRequestor] = useState<string | undefined>(undefined);
  const [selectedEngagementRequest, setSelectedEngagementRequest] = useState<ProfessionalEngagementRequest | null>(null);
  const [selectedEngagementRequestUser, setSelectedEngagementRequestUser] = useState<User | null>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const requestStatuses = ['Pending', 'In Progress', 'Completed', 'Declined', 'Cancelled'];

  const fetch = async () => {
    if (!user) return;
    await fetchProfessionalEngagementRequests(user, user.uid)
      .then((res: { json: () => any }) => res.json())
      .then((result: React.SetStateAction<ProfessionalEngagementRequest[]>) => setEngagementRequests(result));
  };

  useEffect(() => {
    if (!user) return;

    fetch().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  type requestDisplayData = {
    services: string[];
    status: string;
    date: Date;
    client: userTableCellDisplayData;
    location: string;
    price: number;
    id: number;
  };

  const tabledata: requestDisplayData[] = engagementRequests
    .map((er) => ({
      services: er.requestedservices,
      status: er.status,
      date: er.requestdate,
      client: {
        name: er.clientdisplayname,
        avatarurl: er.clientuseravatarurl,
      },
      location: er.clientlocation,
      price: er.estimatedvalue,
      id: er.id,
    }))
    .filter((er) => er.status === requestStatuses[selectedTabIndex]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedtabledata = useMemo(() => tabledata, [selectedTabIndex, engagementRequests]);

  const columnHelper = createColumnHelper<requestDisplayData>();

  const columns = [
    columnHelper.accessor('services', {
      cell: (info: any) => info.getValue().join(', \n'),
      header: 'Services Requested ',
    }),
    columnHelper.accessor('status', {
      cell: (info: any) => info.getValue(),
      header: 'Status',
    }),
    columnHelper.accessor('date', {
      cell: (info: any) => moment(info.getValue()).format('MMM DD'),
      header: 'Date Requested',
      meta: {
        isDate: true,
      },
    }),
    columnHelper.accessor('client', {
      // TODO: extract this to a function
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info: any) => (
        <HStack>
          <Avatar src={info.getValue().avatarurl} size="sm" />
          <Text>{info.getValue().name}</Text>
        </HStack>
      ),
      header: 'Client',
    }),
    columnHelper.accessor('location', {
      cell: (info: any) => info.getValue(),
      header: 'Location',
    }),
    columnHelper.accessor('price', {
      cell: (info: any) => `$${info.getValue()}`,
      header: 'Price',
      meta: {
        isNumeric: true,
      },
    }),
  ];

  const rowSelectedHandler = (index: number) => {
    const erid = tabledata[index].id;
    const er = engagementRequests.filter((e) => e.id === erid)[0];
    setSelectedEngagementRequest(er);
    fetchUser(user as UserAuth, er.clientuserid)
      .then((res: { json: () => any }) => res.json())
      .then((result: User) => setSelectedEngagementRequestUser(result));
    onOpen();
  };

  const handleCompleteEngagement = async () => {
    if (!user) return;
    if (!selectedEngagementRequest) return;

    const dto: CompleteEngagementDTO = {
      engagementId: selectedEngagementRequest.id,
      reviewrating: 0,
    };
    await completeProfessionalEngagement(user, dto).then(() => {
      selectedEngagementRequest.status = 'Completed';
      updateEngagementRequest(user, user.uid, selectedEngagementRequest.id, selectedEngagementRequest)
        .then((result) => {
          fetch();
          setSelectedEngagementRequest(null);
          onClose();
        })
        .catch((er) => console.log(er));
    });
  };

  const renderDetailsDrawer = () => {
    if (!selectedEngagementRequest) return <Box />;
    if (!selectedEngagementRequestUser) return <Spinner />;
    return (
      <Flex flexDir="column" flex={1}>
        <Flex flexDir="column" flex={1} width="100%" mb={4}>
          <Flex alignItems="center" mb={4}>
            <Text variant="header" color="charcoal" size="md" mr={2}>
              Client
            </Text>
          </Flex>
          <ClientDetailsDisplay user={selectedEngagementRequestUser} />
          <Flex alignItems="center" my={4}>
            <Text variant="header" color="charcoal" size="md" mr={2}>
              Services & Earnings
            </Text>
          </Flex>
          <Flex flexDir="column">
            <Flex bg="olive.400" mt={2} my={3} py={1} px={3} borderRadius="lg" maxW="40%" justifyContent="center">
              <Text color="white" fontWeight="bold">
                Total Earning Potential: ${selectedEngagementRequest.estimatedvalue}
              </Text>
            </Flex>
            <Text color="orange.400" fontWeight="bold" mb={1}>
              Services Selected
            </Text>
            {selectedEngagementRequest.requestedservices.map((service) => (
              <Text color="charcoal" key={service} p={1} ml={1}>
                {service}
              </Text>
            ))}
            <Text color="orange.400" fontWeight="bold" mt={2}>
              Engagement Info
            </Text>
            <Flex mt={3} maxW="70%">
              <LabelValueDisplayItem fieldname="Message" fieldvalue={selectedEngagementRequest.requestnotes} />
            </Flex>
            <Flex mt={3} maxW="70%">
              <LabelValueDisplayItem fieldname="Availability" fieldvalue={selectedEngagementRequest.requesttimeframe} />
            </Flex>
            <Flex mt={3} maxW="70%">
              <LabelValueDisplayItem fieldname="In Person or Virtual" fieldvalue={selectedEngagementRequest.isinperson ? 'In Person' : 'Virtual'} />
            </Flex>
            <Flex mt={3} maxW="70%">
              <LabelValueDisplayItem fieldname="Location" fieldvalue={selectedEngagementRequest.clientlocation} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderDetailsDrawerFooter = () => {
    if (!selectedEngagementRequest) return <Box />;
    if (selectedEngagementRequest.status === 'Pending') {
      return (
        <>
          <Button mr={3} variant="darkoutline" onClick={() => setShowDeclineModal(true)}>
            Decline Request
          </Button>
          <Button variant="solid" onClick={() => setShowAcceptModal(true)}>
            Accept Request
          </Button>
        </>
      );
    }
    if (selectedEngagementRequest.status === 'In Progress' || selectedEngagementRequest.status === 'Active') {
      return (
        <Flex alignItems="center" my={4}>
          <Button variant="solid" onClick={handleCompleteEngagement}>
            Complete Engagement
          </Button>
        </Flex>
      );
    }
    return <Box />;
  };

  const handleAcceptRequest = async () => {
    if (!user) return;
    if (!selectedEngagementRequest) return;
    const msg = messageToRequestor || 'Request accepted, you are about to look amazing!';
    await sendAcceptProfessionalEngagementRequest(user as UserAuth, user.uid, selectedEngagementRequest.id, msg as string)
      .then((res) => res.json())
      .then((result) => {
        // const clone = [...engagementRequests];
        // const filtered = clone.filter((er) => er.id !== selectedEngagementRequest.id);
        // setEngagementRequests(filtered);
        fetch();
        setSelectedEngagementRequest(null);
        setSelectedTabIndex(requestStatuses.indexOf('In Progress'));
      });
  };

  const handleDeclineRequest = async () => {
    if (!user) return;
    if (!selectedEngagementRequest) return;
    const msg = messageToRequestor || 'Request declined.';
    await sendDeclineProfessionalEngagementRequest(user as UserAuth, user.uid, selectedEngagementRequest.id, msg as string)
      .then((res) => res.json())
      .then((result) => {
        // const clone = [...engagementRequests];
        // const filtered = clone.filter((er) => er.id !== selectedEngagementRequest.id);
        // setEngagementRequests(filtered);
        fetch();
        setSelectedEngagementRequest(null);
        setSelectedTabIndex(requestStatuses.indexOf('Declined'));
      });
  };

  if (!engagementRequests) return <Spinner />;

  return (
    <Flex flexDirection="column" m={4} px={12} py={8} flex={1} width="100%">
      <Flex alignItems="center" flex={1} width="100%" mb={8}>
        <Text variant="header" color="charcoal" fontWeight="bold" mr={6}>
          Requests
        </Text>
        <Tabs flex={1} isFitted variant="unstyled" index={selectedTabIndex} onChange={(index) => setSelectedTabIndex(index)}>
          <TabList>
            {requestStatuses &&
              requestStatuses.map((status) => (
                <Tab
                  key={status}
                  _selected={{
                    color: 'orange.400',
                    borderBottomColor: 'orange.400',
                    borderBottomWidth: 4,
                    borderBottomRadius: 4,
                  }}
                  color="grey.400"
                >
                  {status}
                </Tab>
              ))}
          </TabList>
        </Tabs>
      </Flex>
      <DataTable columns={columns} data={memoizedtabledata} shadeAlternatingRows={false} handleRowSelected={rowSelectedHandler} />
      <Drawer placement="right" size="xl" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent bg="white">
          <DrawerHeader borderBottomWidth="1px">
            <Text variant="header" color="black">
              Request Details
            </Text>
          </DrawerHeader>
          <DrawerBody mt={2}>{renderDetailsDrawer()}</DrawerBody>
          <DrawerFooter>{renderDetailsDrawerFooter()}</DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Modal size="md" isOpen={showDeclineModal} onClose={() => setShowDeclineModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Decline Service Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Do you want to provide information on why you are declining this user’s request? If so, please input more details below.</Text>
            <Textarea size="lg" value={messageToRequestor} onChange={(e) => setMessageToRequestor(e.target.value)} />
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              color="red.400"
              bg="white"
              borderRadius="2xl"
              borderColor="red.400"
              borderWidth={1}
              px={4}
              size="sm"
              onClick={() => {
                onClose();
                setShowDeclineModal(false);
                handleDeclineRequest();
              }}
            >
              Decline without Messaging
            </Button>
            <Button
              bg="red.400"
              color="white"
              borderRadius="2xl"
              px={4}
              size="sm"
              onClick={() => {
                onClose();
                setShowDeclineModal(false);
                handleDeclineRequest();
              }}
            >
              Decline & Send Message
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal size="md" isOpen={showAcceptModal} onClose={() => setShowAcceptModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Next Step: Connect with Client</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Congrats on booking this service! As a next step, please message client to finalize details and schedule service(s).</Text>
            <Textarea size="lg" value={messageToRequestor} onChange={(e) => setMessageToRequestor(e.target.value)} />
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              variant="darkoutline"
              px={4}
              size="sm"
              onClick={() => {
                onClose();
                setShowAcceptModal(false);
                handleAcceptRequest();
              }}
            >
              Message Later
            </Button>
            <Button
              variant="solid"
              px={4}
              size="sm"
              onClick={() => {
                onClose();
                setShowAcceptModal(false);
                handleAcceptRequest();
              }}
            >
              Send Message
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default RequestsScreen;
