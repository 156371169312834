import { Flex, Avatar, Text } from '@chakra-ui/react';
import React from 'react';
import moment from 'moment';
import { Rating } from 'react-simple-star-rating';
import { ProfessionalReview } from '../../models/ProfessionalReview';

type ProfessionalReviewCardProps = {
  review: ProfessionalReview;
};

function ProfessionalReviewCard({ review }: ProfessionalReviewCardProps) {
  return (
    <Flex flexDirection="column" borderWidth={1} borderRadius="md" p={4}>
      <Flex alignItems="center" mb={2}>
        <Avatar src={review.reviewuseravatarurl} size="md" />
        <Flex flexDirection="column" my={1} ml={4} alignItems="flex-start">
          <Flex alignItems="center">
            <Text fontWeight="bold">{review.reviewuserdisplayname}</Text>
            {review.reviewuserlocation && (
              <Text fontSize="xs" ml={2}>
                [{review.reviewuserlocation}]
              </Text>
            )}
          </Flex>
          <Flex alignItems="center">
            <Rating initialValue={review.rating} allowFraction SVGstyle={{ display: 'inline-block' }} size={14} readonly />
            <Text fontSize="xs" ml={1}>
              {moment(review.reviewdate).format('M/D/YY')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        <Text noOfLines={3}>{review.review}</Text>
      </Flex>
    </Flex>
  );
}

export default ProfessionalReviewCard;
