/* eslint-disable no-octal */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
// import { User } from 'firebase/auth';
import moment from 'moment';
import { Box, Text, Heading, HStack, Flex, Select, Button, Highlight, Spinner, Avatar, Center, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { createColumnHelper } from '@tanstack/react-table';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import {
  deleteProfessionalOnboardingRequirements,
  fetchProfessionalMonthlyEarnings,
  getClientListForProfessional,
  updateProfessionalDetails,
  updateProfessionalOnboardingRequirements,
} from '../services/ProfessionalsService';
import { ProfessionalEngagementRequest } from '../models/ProfessionalEngagementRequest';
import { DataTable, userTableCellDisplayData } from '../components/DataTable';
import ToDos from '../components/ToDos';
import LoadingScreen from '../components/LoadingScreen';
import { Client } from '../models/Client';
import { fetchProfessionalEngagementRequests } from '../services/EngagementsService';
import { CurrentProfessionalContext, CurrentProfessionalContextType } from '../contexts/CurrentProfessionalProvider';
import { ProfessionalOnboardingRequirements, ProfessionalUser } from '../models/ProfessionalUser';
import ProfileActivationControl from '../components/ProfileActivationControl';

function HomeScreen() {
  const { currentUser } = useContext(CurrentUserContext);
  const { currentProfessional, setCurrentProfessional }: CurrentProfessionalContextType = useContext(CurrentProfessionalContext);
  const [onboardingRequirements, setOnboardingRequirements] = useState<ProfessionalOnboardingRequirements | undefined>(
    currentProfessional.onboardingRequirements
  );
  const navigate = useNavigate();
  const toast = useToast();
  // TODO: decide if status is In Progress or Active or Accepted
  const [loadingRequests, setLoadingRequests] = useState(false);
  const [clientList, setClientList] = useState<Client[]>([]);
  const [pendingRequests, setPendingRequests] = useState<ProfessionalEngagementRequest[]>([]);
  const [user, loading, error] = useAuthState(auth);
  const [monthlyEarnings, setMonthlyEarnings] = useState<number | undefined>();

  useEffect(() => {
    if (!user) return;
    if (!currentUser?.userid) return;
    const getRequests = async () => {
      setLoadingRequests(true);
      await fetchProfessionalEngagementRequests(user, currentUser.userid)
        .then((res) => res.json())
        .then((result: ProfessionalEngagementRequest[]) => {
          // const inprog = [...result].filter((r) => r.status.toLowerCase() === 'in progress').sort((a, b) => b.requestdate.valueOf() - a.requestdate.valueOf());
          // setInProgressRequests(inprog);
          const pend = [...result].filter((r) => r.status.toLowerCase() === 'pending').sort((a, b) => b.requestdate.valueOf() - a.requestdate.valueOf());
          setPendingRequests(pend);
          setLoadingRequests(false);
        });
    };
    getRequests().catch(console.error);
  }, [currentUser.userid, user]);

  useEffect(() => {
    if (!user) return;
    if (!currentUser) return;
    const getEarnings = async () => {
      await fetchProfessionalMonthlyEarnings(user, currentUser.userid)
        .then((res) => res.json())
        .then((result: number) => {
          setMonthlyEarnings(result);
        });
    };
    getEarnings().catch(console.error);
  }, [currentUser, user]);

  useEffect(() => {
    if (!user) return;
    if (clientList && clientList.length > 0) return;
    const fetchClientList = async () => {
      await getClientListForProfessional(user)
        .then((res) => res.json())
        .then((result: Client[]) => {
          // eslint-disable-next-line no-param-reassign
          result = result.filter((cl) => cl.currentstatus === 'Active');
          result.sort((a, b) => a.currentstatus.localeCompare(b.currentstatus));
          setClientList(result);
        });
    };
    fetchClientList().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const renderFTUE = () => {
    if (!currentProfessional) return <Box />;
    const handleCompleteOnboardingTask = (taskName: string) => {
      const clone: ProfessionalOnboardingRequirements = structuredClone(onboardingRequirements);
      if (taskName === 'availabilityRequired') clone.availabilityRequired = false;
      if (taskName === 'servicesRequired') clone.servicesRequired = false;
      if (taskName === 'profileRequired') clone.profileRequired = false;
      if (!clone.availabilityRequired && !clone.servicesRequired && !clone.profileRequired) {
        deleteProfessionalOnboardingRequirements(user as UserAuth).then(() => {
          setOnboardingRequirements(undefined);
          setCurrentProfessional({ ...currentProfessional, onboardingRequirements: undefined });
        });
      } else {
        updateProfessionalOnboardingRequirements(user as UserAuth, clone).then(() => {
          setOnboardingRequirements(clone);
          setCurrentProfessional({ ...currentProfessional, onboardingRequirements: clone });
        });
      }
    };
    return (
      <Flex flexDirection="row" alignItems="center" mb={6}>
        {onboardingRequirements && onboardingRequirements.availabilityRequired && (
          <Flex flexDirection="column" borderWidth={1} p={2} maxWidth={72} mr={4}>
            <Flex flexDirection="row" justifyContent="flex-end">
              {/* <Text color="white" bg="red.500" px={2}>
                Required
              </Text> */}
              <CloseIcon color="orange.400" onClick={() => handleCompleteOnboardingTask('availabilityRequired')} />
            </Flex>
            <Flex flexDirection="column" p={4}>
              <Text fontSize="lg" fontWeight="bold" wordBreak="break-word">
                Set Availability
              </Text>
              <Text>Set your upcoming schedule to show clients your availability</Text>
              <Button variant="solid" mt={4} maxW="75%" onClick={() => navigate('/availablity')}>
                Set Availablity
              </Button>
            </Flex>
          </Flex>
        )}
        {onboardingRequirements && onboardingRequirements.servicesRequired && (
          <Flex flexDirection="column" borderWidth={1} p={2} maxWidth={72} mr={4}>
            <Flex flexDirection="row" justifyContent="flex-end">
              {/* <Text color="white" bg="red.500" px={2}>
                Required
              </Text> */}
              <CloseIcon color="orange.400" onClick={() => handleCompleteOnboardingTask('servicesRequired')} />
            </Flex>
            <Flex flexDirection="column" p={4}>
              <Text fontSize="lg" fontWeight="bold" wordBreak="break-word">
                Enter Service Details
              </Text>
              <Text>Specify which services you provide in order to be matched to clients</Text>
              <Button variant="solid" mt={4} maxW="75%" onClick={() => navigate('/account', { state: { tab: 'Services' } })}>
                Enter Service Details
              </Button>
            </Flex>
          </Flex>
        )}
        {onboardingRequirements && onboardingRequirements.profileRequired && (
          <Flex flexDirection="column" borderWidth={1} p={2} maxWidth={72} mr={4}>
            <Flex flexDirection="row" justifyContent="flex-end">
              {/* <Text color="white" bg="red.500" px={2}>
                Required
              </Text> */}
              <CloseIcon color="orange.400" onClick={() => handleCompleteOnboardingTask('profileRequired')} />
            </Flex>
            <Flex flexDirection="column" p={4}>
              <Text fontSize="lg" fontWeight="bold" wordBreak="break-word">
                Finalize Profile
              </Text>
              <Text>Add profile details and showcase work to market your expertise</Text>
              <Button variant="solid" mt={4} maxW="75%" onClick={() => navigate('/account', { state: { tab: 'Profile' } })}>
                Finalize Profile
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    );
  };

  const renderActiveClientsGrid = () => {
    type clientDisplayData = {
      client: userTableCellDisplayData;
      location: string | undefined;
      // earnings: number;
      duedate: Date | undefined;
      services: string[];
      id: number;
    };

    const tabledata: clientDisplayData[] = clientList.map((er) => ({
      client: {
        name: er.user.username,
        avatarurl: er.user.useravatarurl,
      },
      location: er.user.address?.city,
      // earnings: er.totalearnings,
      duedate: er.engagements.sort((a, b) => ((a.targetEndDate as unknown as Date) > (b.targetEndDate as unknown as Date) ? 1 : -1))[0].targetEndDate,
      services: er.engagements.flatMap((engs) => engs.requestedservices),
      id: er.user.id,
    }));

    const columnHelper = createColumnHelper<clientDisplayData>();

    const columns = [
      columnHelper.accessor('client', {
        // TODO: extract this to a function
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info: any) => (
          <HStack>
            <Avatar src={info.getValue().avatarurl} size="sm" />
            <Text>{info.getValue().name}</Text>
          </HStack>
        ),
        header: 'Client',
      }),
      columnHelper.accessor('services', {
        cell: (info: any) => info.getValue().join(', \n'),
        header: 'Requested Services ',
      }),
      columnHelper.accessor('location', {
        cell: (info: any) => info.getValue(),
        header: 'Location',
      }),
      // columnHelper.accessor('earnings', {
      //   cell: (info: any) => `$${info.getValue()}`,
      //   header: 'Earnings',
      //   meta: {
      //     isNumeric: true,
      //   },
      // }),
      columnHelper.accessor('duedate', {
        cell: (info: any) => (info.getValue() && moment(info.getValue()).year() !== 0o1 ? moment(info.getValue()).format('MMM DD YYYY') : 'TBD'),
        header: 'Due Date',
      }),
    ];

    const rowSelectedHandler = (index: number) => {
      const rowid = tabledata[index].id;
      const client = clientList.filter((i) => i.user.id === rowid)[0];
      navigate('/clientdetails', { state: { clientuserid: client.user.userid } });
    };

    return (
      <Flex flexDirection="column" width="100%">
        <HStack justifyContent="space-between" px={6}>
          <Text variant="header" flex={1} my={2}>
            Active Clients
          </Text>
        </HStack>
        <DataTable columns={columns} data={tabledata} shadeAlternatingRows={false} handleRowSelected={rowSelectedHandler} />
        {tabledata.length === 0 && (
          <Flex flex={1} flexDirection="column" justifyContent="center" alignItems="center" p={24} borderWidth={1} borderRadius="md" color="grey.400">
            <Text mb={4} fontSize="lg">
              No Active Clients
            </Text>
            <Text>After you have booked your first client you will see details here.</Text>
          </Flex>
        )}
      </Flex>
    );
  };

  const renderPendingRequests = () => {
    if (!pendingRequests) return <Spinner colorScheme="orange" size="xl" />;

    type requestDisplayData = {
      services: string[];
      date: Date;
      id: number;
    };

    const tabledata: requestDisplayData[] = pendingRequests.map((er) => ({
      services: er.requestedservices,
      date: er.requestdate,
      id: er.id,
    }));

    const columnHelper = createColumnHelper<requestDisplayData>();

    const columns = [
      columnHelper.accessor('services', {
        cell: (info: any) => info.getValue().join(', \n'),
        header: 'Service(s)',
      }),
      columnHelper.accessor('date', {
        cell: (info: any) => moment(info.getValue()).format('MMM DD h:mm'),
        header: 'Date',
        meta: {
          isDate: true,
        },
      }),
    ];

    const handleSort = (sortval: string) => {
      const clone = [...pendingRequests];
      const sorted = clone.sort((a, b) => {
        if (a && b && sortval === 'datedesc' && a.requestdate && b.requestdate) return b.requestdate.valueOf() > a.requestdate.valueOf() ? 1 : -1;
        if (a && b && sortval === 'dateasc' && a.requestdate && b.requestdate) return a.requestdate.valueOf() > b.requestdate.valueOf() ? 1 : -1;
        if (a && b && sortval === 'service') return a.requestedservices.join(', ').localeCompare(b.requestedservices.join(', '));
        return 0;
      });
      setPendingRequests(sorted);
    };

    const rowSelectedHandler = (index: number) => {
      const rowid = tabledata[index].id;
      const item = pendingRequests.filter((i) => i.id === rowid)[0];
      navigate('/requests', { state: { requestid: item.id } });
    };

    return (
      <Flex flexDirection="column" flex={1}>
        <Flex width="100%" pl={6} mb={2}>
          <Text variant="header" flex={3} my={2}>
            Pending Requests
          </Text>
          <Select variant="outline" flex={2} onChange={(e) => handleSort(e.target.value)}>
            <option value="datedesc">Newest</option>
            <option value="dateasc">Oldest</option>
            <option value="service">Service(s)</option>
          </Select>
          <Button ml={2} variant="solid" onClick={() => navigate('/requests')}>
            See All
          </Button>
        </Flex>
        {tabledata && tabledata.length > 0 && (
          <DataTable columns={columns} data={tabledata} shadeAlternatingRows={false} handleRowSelected={rowSelectedHandler} />
        )}
        {tabledata.length === 0 && (
          <Flex flex={1} flexDirection="column" justifyContent="center" alignItems="center" p={16} borderWidth={1} borderRadius="md" color="grey.400">
            <Text mb={4} fontSize="lg">
              No Pending Requests
            </Text>
            <Text>Once a potential client sends a request, you can review, accept, or decline it here.</Text>
          </Flex>
        )}
      </Flex>
    );
  };

  const handleToggleProfileActive = (field: string, value: any) => {
    const clone: ProfessionalUser = structuredClone(currentProfessional);
    clone.visible_to_clients = value;
    setCurrentProfessional(clone);

    updateProfessionalDetails(user as UserAuth, clone as ProfessionalUser).then((result) => {
      if (result.ok) {
        toast({
          title: 'Profile Saved',
          description: 'Data saved successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      }
    });
  };

  if (!user) return <LoadingScreen />;

  return (
    <Flex flexDirection="column" height="100%" flex={1} px={12} py={8}>
      <HStack m={2} justifyContent="space-between">
        <Text variant="header" color="black" size="lg">
          Welcome,&nbsp;
          {currentUser.firstname}!
        </Text>
        <Flex flexDirection="row">
          {currentProfessional && (currentProfessional.onboardingRequirements || !currentProfessional.visible_to_clients) && (
            <ProfileActivationControl
              togglevalue={currentProfessional.visible_to_clients}
              handleOnChange={handleToggleProfileActive}
              handleAutoSave={() => console.log('saved')}
              userdata={currentUser}
              prouser={currentProfessional}
              mr={6}
            />
          )}
          {currentProfessional && !currentProfessional.onboardingRequirements && (
            <Box px={4} py={2} rounded="full" bg="olive.400" color="white" fontWeight="bold">
              Earnings this month: ${monthlyEarnings}
            </Box>
          )}
        </Flex>
      </HStack>

      {currentProfessional && currentProfessional.onboardingRequirements && renderFTUE()}

      <Flex width="100%" my={4} mb={12}>
        {renderActiveClientsGrid()}
      </Flex>

      <Flex flex={1} width="100%" flexDirection="row" my={4} alignItems="flex-start">
        <Box flex={1} mr={4}>
          <ToDos />
        </Box>
        <Box ml={4} flex={1}>
          {renderPendingRequests()}
        </Box>
      </Flex>
    </Flex>
  );
}

export default HomeScreen;
