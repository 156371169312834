/* eslint-disable react/no-array-index-key */
import moment from 'moment';
import { Flex, Box, Text, Spinner } from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase';
import { CurrentProfessionalContextType, CurrentProfessionalContext } from '../../contexts/CurrentProfessionalProvider';
import { Invoice } from '../../models/Invoice';
import { fetchInvoice } from '../../services/InvoiceService';

type ViewInvoiceProps = {
  invoiceid: number;
  invoiceToDisplay?: Invoice;
};

function ViewInvoiceDetails({ invoiceid, invoiceToDisplay, ...rest }: ViewInvoiceProps) {
  const { currentProfessional }: CurrentProfessionalContextType = useContext(CurrentProfessionalContext);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<Invoice | undefined>(invoiceToDisplay);

  useEffect(() => {
    if (!user) return;
    if (invoiceToDisplay) return;
    const fetch = async () => {
      setLoading(true);
      await fetchInvoice(user, invoiceid)
        .then((res) => res.json())
        .then((result: Invoice) => {
          if (!result) return;
          setInvoice(result);
          setLoading(false);
        });
    };
    fetch().catch(console.error);
  }, [invoiceToDisplay, invoiceid, user]);

  const renderActivity = () => {
    if (!invoice) return <Box />;
    console.log(invoice);
    return (
      <Flex borderRadius="sm" flex={1} borderWidth={1} p={6} flexDir="column">
        <Flex flex={1} justifyContent="space-between" mb={4}>
          <Text fontSize="lg" fontWeight="bold" color="charcoal">
            Activity
          </Text>
          <Box bg="olive.400" p={1} borderRadius="lg" px={2}>
            <Text color="white">Total Invoice Amount: ${invoice?.professionalChargesSubTotal?.toFixed(2)}</Text>
          </Box>
        </Flex>
        <Flex flexDir="column">
          {invoice.serviceRecords &&
            invoice.serviceRecords.map((sr) => (
              <Flex key={sr.id.toString()} justifyContent="space-evenly" mb={4}>
                <Text flex={1}>{sr.service}</Text>
                <Text flex={1}>{moment(sr.start).format('MMM DD, YYYY HH:mm A')}</Text>
                <Text flex={1}>{moment(sr.end).format('MMM DD, YYYY HH:mm A')}</Text>
                <Text flex={1}>${sr.invoicedamount}</Text>
                <Text flex={2}>{sr.notes}</Text>
              </Flex>
            ))}
        </Flex>
      </Flex>
    );
  };

  const renderAdditionalFees = () => {
    if (!invoice) return <Box />;
    return (
      <Flex borderRadius="sm" borderWidth={1} p={6} flex={1} flexDir="column">
        <Flex mb={2}>
          <Text fontSize="lg" fontWeight="bold" color="charcoal">
            Additional Fees
          </Text>
        </Flex>
        <Flex flexDir="column">
          {invoice.additionalFees &&
            invoice.additionalFees.map((af, i) => (
              <Flex key={i.toString()}>
                <Text mx={1} color="orange.400" fontWeight="bold">
                  {af.name}
                </Text>
                <Text color="charcoal" mx={1}>
                  ${af.amount.toFixed(2)}
                </Text>
              </Flex>
            ))}
        </Flex>
      </Flex>
    );
  };

  const renderNotes = () => {
    if (!invoice) return <Box />;
    return (
      <Flex borderRadius="sm" borderWidth={1} p={6} flex={1} flexDir="column">
        <Flex mb={2}>
          <Text fontSize="lg" fontWeight="bold" color="charcoal">
            Notes
          </Text>
        </Flex>
        <Flex flexDir="column">
          <Text noOfLines={4}>{invoice.notes}</Text>
        </Flex>
      </Flex>
    );
  };

  if (loading) return <Spinner />;

  return (
    <Flex flexDir="column" flex={1} mr={3} height="100%" mt={4}>
      {renderActivity()}
      <Flex flex={1} mt={8}>
        <Flex flex={1} mr={4}>
          {renderAdditionalFees()}
        </Flex>
        <Flex flex={1} ml={4}>
          {renderNotes()}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ViewInvoiceDetails;
