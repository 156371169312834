/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
import {
  Box,
  Text,
  Flex,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Icon,
  Spinner,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useFilePicker } from 'use-file-picker';
import { RiEditCircleFill } from 'react-icons/ri';
import * as FontAwesome from 'react-icons/fa';
import { MultiSelect } from 'chakra-multiselect';
import { AddIcon } from '@chakra-ui/icons';
import { User } from '@firebase/auth';
import { PortfolioLink, ProfessionalUser } from '../../models/ProfessionalUser';
import { CurrentProfessionalContext } from '../../contexts/CurrentProfessionalProvider';
import { CurrentUserContext } from '../../contexts/CurrentUserProvider';
import colors from '../../theme/foundations/colors';

type SocialAccountProps = {
  postSaveHandler: (prouser: ProfessionalUser) => void;
};

function SocialAccounts({ postSaveHandler }: SocialAccountProps) {
  const { currentUser } = useContext(CurrentUserContext);
  const { currentProfessional, setCurrentProfessional } = useContext(CurrentProfessionalContext);
  const [prouser, setprouser] = useState<ProfessionalUser>(currentProfessional as ProfessionalUser);
  const [userdata, setUserData] = useState<User>(currentUser as User);
  const [selectedSocialIcon, setSelectedSocialIcon] = useState<PortfolioLink | undefined>(undefined);

  useEffect(() => {
    if (!currentProfessional) return;
    if (prouser) return;
    setprouser(currentProfessional);
  }, [currentProfessional]);

  useEffect(() => {
    if (!currentUser) return;
    if (userdata) return;
    setUserData(currentUser);
  }, [currentUser]);

  if (!prouser) return <Spinner />;
  // if (!prouser.portfolioData?.portfolioLinks) return <Box />;
  return (
    <>
      <Flex>
        {prouser.portfolioData?.portfolioLinks?.map((item, i) => {
          const iconname = `Fa${item.platform}`;
          let faicon = FontAwesome[iconname as keyof typeof FontAwesome];
          if (!faicon) faicon = FontAwesome.FaGlobe;
          return (
            <Flex key={i.toString()} flexDir="column" mx={4}>
              {faicon &&
                React.createElement(faicon, {
                  style: {
                    color: colors.charcoal,
                    height: 42,
                    width: 42,
                    background: '#D9D9D9',
                    opacity: 1,
                    borderRadius: '50%',
                    padding: '8px',
                  },
                })}
              <Icon
                as={RiEditCircleFill}
                boxSize={5}
                color="orange.400"
                transform="translateY(-12px) translateX(26px)"
                onClick={() => setSelectedSocialIcon(item)}
              />
            </Flex>
          );
        })}
        <AddIcon h={10} w={10} p={2} onClick={() => setSelectedSocialIcon({ platform: '', url: '' })} />
      </Flex>
      <Modal size="sm" isCentered isOpen={selectedSocialIcon !== undefined} onClose={() => setSelectedSocialIcon(undefined)}>
        <ModalOverlay />
        <ModalContent borderRadius="lg">
          <ModalHeader>Edit Social Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" justifyContent="center" m={2}>
              {/* if editing, don't allow platform change.  If new, let them choose or enter platform */}
              {selectedSocialIcon && !selectedSocialIcon.platform && (
                <MultiSelect
                  create
                  single
                  options={[
                    { label: 'Instagram', value: 'Instagram' },
                    { label: 'Tiktok', value: 'Tiktok' },
                    { label: 'Facebook', value: 'Facebook' },
                    { label: 'Twitter', value: 'Twitter' },
                    { label: 'Pinterest', value: 'Pinterest' },
                    { label: 'Wix', value: 'Wix' },
                    { label: 'Website', value: 'Website' },
                  ]}
                  value={selectedSocialIcon?.platform || ''}
                  onChange={(e) => setSelectedSocialIcon({ platform: e as string, url: selectedSocialIcon?.url as string })}
                />
              )}
              {selectedSocialIcon && selectedSocialIcon.platform && selectedSocialIcon.platform.length > 0 && <Text>{selectedSocialIcon.platform}</Text>}
              <Input
                type="url"
                my={2}
                value={selectedSocialIcon?.url}
                onChange={(e) => setSelectedSocialIcon({ platform: selectedSocialIcon?.platform as string, url: e.target.value })}
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="darkoutline"
              mr={4}
              onClick={() => {
                setSelectedSocialIcon(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              bg="red.400"
              mr={4}
              onClick={() => {
                const clone: ProfessionalUser = structuredClone(prouser);
                if (!clone.portfolioData) {
                  clone.portfolioData = {
                    portfolioLinks: [],
                    featuredWork: [],
                    featuredOutfitIds: [],
                  };
                }
                if (!clone.portfolioData.portfolioLinks) clone.portfolioData.portfolioLinks = [];
                const existing = clone.portfolioData.portfolioLinks.filter((l) => l.platform === selectedSocialIcon?.platform);
                if (existing && existing[0]) {
                  clone.portfolioData.portfolioLinks = clone.portfolioData.portfolioLinks.filter((l) => l.platform !== existing[0].platform);
                  setprouser(clone);
                  postSaveHandler(clone);
                }
                setSelectedSocialIcon(undefined);
              }}
            >
              Delete
            </Button>
            <Button
              variant="solid"
              onClick={() => {
                const clone: ProfessionalUser = structuredClone(prouser);
                if (!clone.portfolioData) {
                  clone.portfolioData = {
                    portfolioLinks: [],
                    featuredWork: [],
                    featuredOutfitIds: [],
                  };
                }
                if (!clone.portfolioData.portfolioLinks) clone.portfolioData.portfolioLinks = [];
                const existing = clone.portfolioData.portfolioLinks.filter((l) => l.platform === selectedSocialIcon?.platform);
                if (existing && existing[0] && selectedSocialIcon) {
                  existing[0].url = selectedSocialIcon.url as string;
                } else {
                  if (!clone.portfolioData.portfolioLinks) clone.portfolioData.portfolioLinks = [];
                  clone.portfolioData.portfolioLinks.push(selectedSocialIcon as PortfolioLink);
                }
                setprouser(clone);
                setSelectedSocialIcon(undefined);
                postSaveHandler(clone);
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SocialAccounts;
