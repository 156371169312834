/* eslint-disable no-nested-ternary */
import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Image as ChakraImage, Text } from '@chakra-ui/react';
import { useDrag, useDrop, XYCoord } from 'react-dnd';
import { OUTFIT_DISPLAY_HEIGHT } from '../config/ServiceConstants';
import { Outfit } from '../models/Outfit';
import { OutfitDecorator } from '../models/OutfitDecorator';
import { OutfitPart } from '../models/OutfitPart';

// https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_ts/02-drag-around/naive?from-embed=&file=/src/index.tsx:226-262

type DraggableWrapperProps = {
  id: string;
  name: string;
  left: number;
  top: number;
  children: ReactNode;
};

function DraggableWrapper({ id, name, left, top, children }: DraggableWrapperProps) {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'PART', // ??
      item: { id, name, left, top },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, name, left, top]
  );

  // need to specify px to avoid accidentally using chakra presets
  const topstr = `${top}px`;
  const leftstr = `${left}px`;

  return (
    <Box ref={drag} top={topstr} left={leftstr} position="absolute" cursor="move">
      {children}
    </Box>
  );
}

export interface DragItem {
  type: string;
  name: string;
  id: string;
  top: number;
  left: number;
}

export type EditOutfitContainerProps = {
  outfit: Outfit;
  setOutfit: (outfit: Outfit) => void;
  setSelectedOutfitPart: (part: OutfitPart | OutfitDecorator) => void;
};

export function OutfitPartsEditor({ outfit, setOutfit, setSelectedOutfitPart }: EditOutfitContainerProps) {
  const movePart = useCallback(
    (id: string, name: string, left: number, top: number) => {
      if (!outfit) return;
      const clone: Outfit = structuredClone(outfit);

      // keep it in bounds
      const partHeight = clone.parts.filter((i) => i.id.toString() === id)[0].height;
      let partWidth = clone.parts.filter((i) => i.id.toString() === id)[0].width;
      if (!partWidth) clone.parts.filter((i) => i.id.toString() === id)[0].width = 150;
      if (!partWidth) partWidth = 150;
      const newtop = top > OUTFIT_DISPLAY_HEIGHT - partHeight ? OUTFIT_DISPLAY_HEIGHT - partHeight : top < 0 ? 0 : top;
      const newleft = left > OUTFIT_DISPLAY_HEIGHT - partWidth ? OUTFIT_DISPLAY_HEIGHT - partWidth : left < 0 ? 0 : left;

      if (name.indexOf('wardrobeitem') > -1) {
        clone.parts.filter((i) => i.id.toString() === id)[0].top = newtop;
        clone.parts.filter((i) => i.id.toString() === id)[0].left = newleft;
        //
        clone.parts.filter((i) => i.id.toString() === id)[0].sortorder = clone.parts.length === 0 ? 1 : Math.max(...clone.parts.map((p) => p.sortorder)) + 1;
        clone.parts.sort((a, b) => a.sortorder - b.sortorder);
        setSelectedOutfitPart(clone.parts.filter((i) => i.id.toString() === id)[0]);
      }
      if (name.indexOf('decorator') > -1) {
        clone.decorators.filter((i) => i.id.toString() === id)[0].top = top;
        clone.decorators.filter((i) => i.id.toString() === id)[0].left = left;
        //
        clone.decorators.filter((i) => i.id.toString() === id)[0].sortorder =
          clone.decorators.length === 0 ? 1 : Math.max(...clone.decorators.map((p) => p.sortorder)) + 1;
        clone.decorators.sort((a, b) => a.sortorder - b.sortorder);
        setSelectedOutfitPart(clone.decorators.filter((i) => i.id.toString() === id)[0]);
      }
      setOutfit(clone);
    },
    [outfit, setOutfit, setSelectedOutfitPart]
  );
  const [, drop] = useDrop(
    () => ({
      accept: 'PART',
      drop(item: DragItem, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        movePart(item.id, item.name, left, top);
        return undefined;
      },
    }),
    [movePart]
  );

  return (
    <Box ref={drop} width={OUTFIT_DISPLAY_HEIGHT} height={OUTFIT_DISPLAY_HEIGHT} borderWidth={1} borderColor="charcoal" position="relative">
      {outfit?.parts
        .sort((a, b) => a.sortorder - b.sortorder)
        .map((item) => (
          <DraggableWrapper key={item.id.toString()} name={`wardrobeitem.${item.id.toString()}`} id={item.id.toString()} left={item.left} top={item.top}>
            <ChakraImage
              draggable={false}
              src={item.imageUrl}
              height={item.height}
              // width={item.width}
              // fit="contain"
              // zIndex={item.sortorder + 500} // do i need this?
            />
          </DraggableWrapper>
        ))}
      {outfit?.decorators
        .sort((a, b) => a.sortorder - b.sortorder)
        .map((item) => (
          <DraggableWrapper key={item.id.toString()} name={`decorator.${item.id.toString()}`} id={item.id.toString()} left={item.left} top={item.top}>
            <Text draggable={false} height={item.height} fontSize={item.size}>
              {item.content}
            </Text>
          </DraggableWrapper>
        ))}
    </Box>
  );
}
