/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import moment from 'moment';
import {
  Box,
  Text,
  Heading,
  Flex,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Spinner,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Checkbox,
  Divider,
  calc,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { SmallAddIcon, CalendarIcon, ChevronRightIcon, MinusIcon, DeleteIcon } from '@chakra-ui/icons';
import { createColumnHelper } from '@tanstack/table-core';
import { DataTable } from '../components/DataTable';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentProfessionalContext, CurrentProfessionalContextType } from '../contexts/CurrentProfessionalProvider';
import { Invoice, InvoiceAdditionalFee } from '../models/Invoice';
import { createInvoice, fetchInvoice, submitInvoiceToClient, updateInvoice } from '../services/InvoiceService';
import { fetchClientForProfessional } from '../services/ProfessionalsService';
import { Client } from '../models/Client';
import { ProfessionalEngagementDetails } from '../models/ProfessionalEngagementDetails';
import LoadingScreen from '../components/LoadingScreen';
import { ProfessionalEngagementServiceRecord } from '../models/ProfessionalEngagementServiceRecord';
import ServiceRecordEditor from '../components/ServiceRecordEditor';
import { EXPERT_SERVICE_OFFERINGS } from '../models/ExpertServiceOfferings';
import AddIcon from '../components/AddIcon';
import ViewInvoiceDetails from '../components/invoices/ViewInvoiceDetails';
import { fetchCurrentEngagementForProAndClient } from '../services/EngagementsService';

function InvoiceScreen() {
  const { currentProfessional }: CurrentProfessionalContextType = useContext(CurrentProfessionalContext);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { clientuserid, invoiceid } = state;
  const [client, setClient] = useState<Client | undefined>();
  const [currentInvoice, setCurrentInvoice] = useState<Invoice | undefined>();
  const [currentEngagementDetails, setCurrentEngagementDetails] = useState<ProfessionalEngagementDetails>();
  const [showReviewInvoiceView, setShowReviewInvoiceView] = useState(false);
  // const [includedServiceRecords, setIncludedServiceRecords] = useState<ProfessionalEngagementServiceRecord[]>();
  const [selectedServiceRecord, setSelectedServiceRecord] = useState<ProfessionalEngagementServiceRecord | undefined>(undefined);
  const [serviceOptions, setServiceOptions] = useState<any[]>([]);

  useEffect(() => {
    if (!user) return;
    const fetchClientList = async () => {
      await fetchClientForProfessional(user, clientuserid)
        .then((res) => res.json())
        .then((result: Client) => {
          setClient(result);
        });
    };
    fetchClientList().catch(console.error);
  }, [clientuserid, user]);

  useEffect(() => {
    if (!user) return;
    if (!currentProfessional) return;
    // if (!currentInvoice) return;
    const fetchCurrentEngagement = async () => {
      await fetchCurrentEngagementForProAndClient(user, currentProfessional.userid, clientuserid)
        .then((res) => res.json())
        .then((result: ProfessionalEngagementDetails) => {
          if (!result) console.warn('WARN: no active engagement found for this pro and client');
          setCurrentEngagementDetails(result);
        });
    };
    fetchCurrentEngagement().catch(console.error);
  }, [clientuserid, currentProfessional, user]);

  useEffect(() => {
    if (!user) return;

    const fetch = async () => {
      await fetchInvoice(user, invoiceid)
        .then((res) => res.json())
        .then((result: Invoice) => {
          console.log('fetched invoice: ', result);
          setCurrentInvoice(result);
        });
    };
    if (invoiceid && invoiceid > 0) fetch().catch(console.error);

    if (!invoiceid || invoiceid === 0) {
      // this is an unsaved invoice
      if (!currentEngagementDetails) return;
      console.log('no invoice id, creating shell...');
      const inv: Invoice = {
        id: 0,
        professionalengagementid: currentEngagementDetails.engagement.id,
        prouserid: currentProfessional.userid,
        clientuserid,
        status: 'Draft',
        serviceRecords: [],
        additionalFees: [],
      };
      setCurrentInvoice(inv);
    }
  }, [invoiceid, user, currentEngagementDetails, currentProfessional.userid, clientuserid]);

  useEffect(() => {
    if (!currentProfessional) return;
    const servicesOfferedByPro = currentProfessional.serviceDetails.map((sd) => sd.serviceName);
    const servicesOffered = EXPERT_SERVICE_OFFERINGS.filter((so) => servicesOfferedByPro.includes(so.value));
    if (servicesOffered && servicesOffered.length > 0) setServiceOptions(servicesOffered);
    else setServiceOptions(EXPERT_SERVICE_OFFERINGS);
  }, [currentProfessional]);

  useEffect(() => {
    if (!user) return;
    if (!currentInvoice) return;
    if (!currentEngagementDetails) return;
    // if (includedServiceRecords && includedServiceRecords.length > 0) return;
    if (currentInvoice && currentInvoice.serviceRecords && currentInvoice.serviceRecords?.length > 0) return; // this is important to avoid rerenders
    // default to including all serivce records in the engagement that have not yet been invoiced:
    const unbilledrecords = currentEngagementDetails.serviceRecords.filter((sr) => sr.invoiceid === null || sr.invoiceid === undefined || sr.invoiceid === 0);
    unbilledrecords.forEach((r) => {
      if (r.end) r.invoiceid = currentInvoice.id;
    });
    unbilledrecords.sort((a, b) => ((a.start as unknown as Date) > (b.start as unknown as Date) ? 1 : -1));

    const clone: Invoice = structuredClone(currentInvoice);
    clone.serviceRecords = unbilledrecords;
    setCurrentInvoice(clone);
  }, [user, currentEngagementDetails, currentInvoice]);

  const calculateTotalInvoiceAmount = useMemo(() => {
    if (!currentInvoice) return 0.0;
    if (!currentInvoice?.serviceRecords && !currentInvoice?.additionalFees) return 0.0;
    const clone: ProfessionalEngagementServiceRecord[] = structuredClone(currentInvoice.serviceRecords);
    let lineitemtotal = 0.0;
    let additionalfeetotal = 0.0;

    if (clone && clone.length > 0) {
      const charges = clone
        .filter((sr) => sr.invoiceid === currentInvoice.id)
        .map((sr) => {
          if (!sr.invoicedamount) sr.invoicedamount = sr.calculatedcharge ? sr.calculatedcharge : 0.0;
          return sr.invoicedamount;
        });
      if (charges && charges.length > 0) lineitemtotal = charges.reduce((a, b) => a + b);
    }

    const additionalfees: InvoiceAdditionalFee[] = structuredClone(currentInvoice.additionalFees);
    additionalfeetotal = additionalfees && additionalfees.length > 0 ? additionalfees.map((af) => af.amount).reduce((a, b) => a + b) : 0.0;

    return Math.round((lineitemtotal + additionalfeetotal) * 100) / 100;
  }, [currentInvoice]);

  const renderHeader = () => {
    if (!currentInvoice) return <Box />;
    return (
      <>
        <Breadcrumb spacing="8px" mb={8} separator={<ChevronRightIcon color="blue.400" />}>
          <BreadcrumbItem>
            <BreadcrumbLink href="/Clients">Clients</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text onClick={() => navigate('/clientdetails', { state: { clientuserid } })}>
              {client?.user.firstname} {client?.user.lastname}
            </Text>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text onClick={() => navigate('/clientdetails', { state: { clientuserid, tab: 'Invoices' } })}>Invoices</Text>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text>{currentInvoice.id > 0 ? 'Edit Invoice' : 'Create New Invoice'}</Text>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Flex>
            <Flex flexDir="column">
              <Text variant="header" color="charcoal">
                {currentInvoice.id > 0 ? 'Edit Invoice' : 'Create New Invoice'}
              </Text>
              <Text color="orange.400" fontSize="lg" fontWeight={500}>
                Client: {client?.user.firstname} {client?.user.lastname}
              </Text>
            </Flex>
            <Box bg="grey.100" mx={6} my={4} maxH={18} px={2}>
              <Text>Invoice #: {currentInvoice.id}</Text>
            </Box>
          </Flex>
          <Box bg="olive.400" py={2} px={3} borderRadius="md">
            <Text color="white" fontWeight="bold">
              Total Invoice Earnings: ${calculateTotalInvoiceAmount.toFixed(2)}
            </Text>
          </Box>
        </Flex>
      </>
    );
  };

  const renderActivityDetails = () => {
    if (!currentEngagementDetails) return <Spinner />;
    if (!currentInvoice?.serviceRecords || currentInvoice.serviceRecords.length < 1) return <Text>No unbilled service records</Text>;

    type serviceRecordsDisplayData = {
      service: string;
      start: string;
      end: string;
      hours: string;
      earnings?: number;
      notes: string;
      invoice?: number;
      id: number;
      isselected: boolean;
    };

    const tabledata: serviceRecordsDisplayData[] = currentInvoice.serviceRecords.map((i) => ({
      service: i.service as string,
      start: moment(i.start).format('MM/DD/YY HH:mm'),
      end: i.end ? moment(i.end).format('MM/DD/YY HH:mm') : '',
      hours: i.end ? moment(i.end).diff(moment(i.start), 'hours', true).toFixed(1) : '',
      earnings: i.invoicedamount ? i.invoicedamount : i.calculatedcharge,
      notes: i.notes as string,
      invoice: i.invoiceid,
      id: i.id,
      isselected: i.invoiceid === currentInvoice?.id,
    }));

    const columnHelper = createColumnHelper<serviceRecordsDisplayData>();

    const columns = [
      columnHelper.accessor('isselected', {
        // TODO: extract this to a function
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info: any) => (
          <Box>
            <Checkbox
              isChecked={info.getValue()}
              value={info.getValue()}
              colorScheme="orange"
              onChange={(e) => {
                e.preventDefault();
                const indx = info.row.index;
                const { checked } = e.target;
                console.log('indx: ', indx, ' checked: ', e.target.checked);
                const clone: Invoice = structuredClone(currentInvoice);
                if (!clone.serviceRecords) clone.serviceRecords = [];
                if (!clone.serviceRecords[indx].end) return;
                if (checked) clone.serviceRecords[indx].invoiceid = currentInvoice?.id;
                if (!checked) clone.serviceRecords[indx].invoiceid = undefined;
                // TODO: should I actually remove it
                setCurrentInvoice(clone);
                //
                // const clone = structuredClone(includedServiceRecords);
                // if (!clone[indx].end) return;
                // if (checked) clone[indx].invoiceid = currentInvoice?.id;
                // if (!checked) clone[indx].invoiceid = undefined;
                // // TODO: should I actually remove it
                // setIncludedServiceRecords(clone);
              }}
            />
          </Box>
        ),
        header: 'Select',
      }),
      columnHelper.accessor('service', {
        cell: (info: any) => info.getValue(),
        header: 'Service',
      }),
      columnHelper.accessor('start', {
        cell: (info: any) => info.getValue(),
        header: 'Start',
      }),
      columnHelper.accessor('end', {
        cell: (info: any) => info.getValue(),
        header: 'End',
      }),
      columnHelper.accessor('hours', {
        cell: (info: any) => info.getValue(),
        header: 'Hours',
      }),
      columnHelper.accessor('earnings', {
        cell: (info: any) => `$${info.getValue()}`,
        header: 'Earnings',
      }),
      columnHelper.accessor('notes', {
        cell: (info: any) => info.getValue(),
        header: 'Notes',
      }),
      columnHelper.accessor('id', {
        // TODO: extract this to a function
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info: any) => (
          <Box
            onClick={(e) => {
              e.preventDefault();
              const rowid = info.getValue();
              console.log('Edit clicked, rowid: ', rowid);
              const clone: ProfessionalEngagementServiceRecord[] = structuredClone(currentInvoice.serviceRecords);
              const record = clone.filter((i) => i.id === rowid)[0];
              // // format dates so that react-datepicker can ingest them:
              record.start = moment(record.start).toDate();
              record.end = record.end ? moment(record.end).toDate() : undefined;
              console.log('selectedServiceRecord: ', record);
              setSelectedServiceRecord(record);
            }}
          >
            <Text fontWeight="bold" color="orange.400">
              Edit
            </Text>
          </Box>
        ),
        header: 'Select',
      }),
    ];

    const handleServiceRecordCreated = (sr: ProfessionalEngagementServiceRecord) => {
      const clone: Invoice = structuredClone(currentInvoice);
      clone.serviceRecords?.push(sr);
      setCurrentInvoice(clone);
    };

    const handleServiceRecordUpdated = (sr: ProfessionalEngagementServiceRecord) => {
      const clone: Invoice = structuredClone(currentInvoice);
      if (!clone.serviceRecords) return;
      const indx = clone.serviceRecords.findIndex((x) => x.id === sr.id);
      clone.serviceRecords[indx] = sr;
      setCurrentInvoice(clone);
    };

    const handleServiceRecordDeleted = (srid: number) => {
      const clone: Invoice = structuredClone(currentInvoice);
      if (!clone.serviceRecords) return;
      clone.serviceRecords = clone.serviceRecords.filter((sr) => sr.id !== srid);
      setCurrentInvoice(clone);
      setSelectedServiceRecord(undefined);
    };

    return (
      <Flex flexDir="column" flex={1}>
        <Flex justifyContent="space-between" flex={1}>
          <Text fontSize="2xl" fontWeight="bold">
            Step 1: Select Activities To Include
          </Text>
          <Button
            variant="solid"
            leftIcon={<SmallAddIcon />}
            onClick={() =>
              setSelectedServiceRecord({
                id: 0,
                clientuserid,
                prouserid: currentProfessional.userid,
                professionalengagementid: currentEngagementDetails?.engagement.id,
                invoiceid: currentInvoice?.id,
              })
            }
          >
            Add Time
          </Button>
        </Flex>
        <Flex flexDir="column" flex={1} mr={3} height="100%" mt={4}>
          <DataTable columns={columns} data={tabledata} shadeAlternatingRows={false} />
          <ServiceRecordEditor
            selectedServiceRecord={selectedServiceRecord}
            setSelectedServiceRecord={setSelectedServiceRecord}
            handleServiceRecordCreated={handleServiceRecordCreated}
            handleServiceRecordUpdated={handleServiceRecordUpdated}
            handleServiceRecordDeleted={handleServiceRecordDeleted}
            clientDisplayName={client?.user.username}
            engagementId={currentEngagementDetails.engagement.id}
            serviceOptions={serviceOptions}
          />
        </Flex>
      </Flex>
    );
  };

  const renderAdditionalFeesSection = () => {
    if (!currentInvoice) return <Box />;
    return (
      <Flex flexDir="column">
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          Step 2: Additional Fees
        </Text>
        {currentInvoice &&
          currentInvoice.additionalFees &&
          currentInvoice.additionalFees.map((fee: InvoiceAdditionalFee, i: number) => (
            <Flex key={i.toString()} justifyContent="center" alignItems="center" mb={4}>
              <Flex flexDir="column">
                <Text>Fee Name / Description</Text>
                <Input
                  value={fee.name}
                  onChange={(e) => {
                    const clone: Invoice = structuredClone(currentInvoice);
                    if (!clone.additionalFees) clone.additionalFees = [];
                    clone.additionalFees[i].name = e.target.value;
                    setCurrentInvoice(clone);
                  }}
                />
              </Flex>
              <Flex flexDir="column" ml={2}>
                <Text>Fee Amount ($)</Text>
                <Input
                  type="number"
                  value={fee.amount}
                  onChange={(e) => {
                    const clone: Invoice = structuredClone(currentInvoice);
                    if (!clone.additionalFees) clone.additionalFees = [];
                    clone.additionalFees[i].amount = e.target.valueAsNumber;
                    setCurrentInvoice(clone);
                  }}
                />
              </Flex>
              <Flex ml={2}>
                <DeleteIcon
                  boxSize={6}
                  p={1}
                  mt={4}
                  onClick={() => {
                    const clone: Invoice = structuredClone(currentInvoice);
                    if (!clone.additionalFees) clone.additionalFees = [];
                    clone.additionalFees.splice(i, 1);
                    setCurrentInvoice(clone);
                  }}
                />
              </Flex>
            </Flex>
          ))}
        <Flex alignItems="center">
          <Text fontSize="lg" mr={2}>
            Add New
          </Text>
          <AddIcon
            onClick={() => {
              const clone: Invoice = structuredClone(currentInvoice);
              if (!clone.additionalFees) clone.additionalFees = [];
              clone.additionalFees.push({
                name: '',
                amount: 0.0,
              });
              setCurrentInvoice(clone);
            }}
          />
        </Flex>
      </Flex>
    );
  };

  const renderNotesSection = () => (
    <Flex flexDir="column" flex={1}>
      <Text fontSize="2xl" fontWeight="bold">
        Step 3: Notes
      </Text>
      <Text size="sm">Notes</Text>
      <Textarea value={currentInvoice?.notes} onChange={(e) => setCurrentInvoice({ ...currentInvoice, notes: e.target.value } as Invoice)} />
    </Flex>
  );

  const handleSaveInvoice = async () => {
    if (!currentInvoice) return;
    // first exclude any non-included service records:
    if (currentInvoice.serviceRecords) {
      currentInvoice.serviceRecords = currentInvoice.serviceRecords.filter((sr) => sr.invoiceid === currentInvoice.id);
    }

    const clone: Invoice = structuredClone(currentInvoice);
    clone.servicesSubTotal = calculateTotalInvoiceAmount;

    if (clone.id === 0) {
      // this is a create
      await createInvoice(user as UserAuth, currentInvoice)
        .then((res) => res.json())
        .then((result) => {
          setCurrentInvoice(result);
        })
        .catch((er) => console.log(er));
    } else {
      // this is an update
      await updateInvoice(user as UserAuth, currentInvoice)
        .then((res) => res.json())
        .then((result) => {
          setCurrentInvoice(result);
        })
        .catch((er) => console.log(er));
    }
  };

  if (!currentInvoice) return <LoadingScreen />;

  if (showReviewInvoiceView)
    return (
      <Flex flexDir="column" flex={1} p={8} mx={16}>
        <Flex flexDirection="column">
          <Text variant="header" color="charcoal">
            Review Invoice #{currentInvoice.id}
          </Text>
          <Text color="orange.400" fontSize="lg">
            Client: {client?.user.firstname} {client?.user.lastname}
          </Text>
        </Flex>
        <ViewInvoiceDetails invoiceid={currentInvoice.id} invoiceToDisplay={currentInvoice} />
        <Flex my={6} justifyContent="flex-end">
          <Button variant="darkoutline" mr={4} onClick={() => setShowReviewInvoiceView(false)}>
            Cancel
          </Button>
          <Button
            variant="solid"
            ml={4}
            onClick={() => {
              handleSaveInvoice().then(() => {
                submitInvoiceToClient(user as UserAuth, currentInvoice.id).then(() => navigate('/clientdetails', { state: { clientuserid, tab: 'Invoices' } }));
              });
            }}
          >
            Submit Invoice
          </Button>
        </Flex>
      </Flex>
    );

  if (currentInvoice && currentInvoice.status !== 'Draft')
    return (
      <Flex flexDir="column" flex={1} p={8} mx={16}>
        <Flex justifyContent="space-between" alignItems="center" mr={6}>
          <Text fontSize="xl" fontWeight="bold" color="blue.400">
            Review Invoice
          </Text>
          <Text fontSize="lg" color="orange.400">
            Client: {client?.user.firstname} {client?.user.lastname}
          </Text>
        </Flex>
        <ViewInvoiceDetails invoiceid={currentInvoice.id} invoiceToDisplay={currentInvoice} />
        <Flex my={6} justifyContent="flex-end">
          <Button variant="darkoutline" mr={4} onClick={() => navigate('/clientdetails', { state: { clientuserid, tab: 'Invoices' } })}>
            Back
          </Button>
        </Flex>
      </Flex>
    );

  return (
    <Flex flexDir="column" flex={1} width="100%" px={12} py={8}>
      {renderHeader()}
      <Flex my={4}>{renderActivityDetails()}</Flex>
      <Flex my={4}>{renderAdditionalFeesSection()}</Flex>
      <Divider my={4} />
      <Flex my={4}>{renderNotesSection()}</Flex>
      <Flex my={2} justifyContent="space-between">
        <Button variant="darkoutline" onClick={() => navigate('/clientdetails', { state: { clientuserid } })}>
          Cancel
        </Button>
        {currentInvoice && currentInvoice.status === 'Draft' && (
          <Flex>
            <Button variant="darkoutline" onClick={handleSaveInvoice}>
              Save Invoice
            </Button>
            <Button variant="solid" isDisabled={!currentInvoice.id || currentInvoice.id === 0} ml={4} onClick={() => setShowReviewInvoiceView(true)}>
              Review & Send Invoice
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default InvoiceScreen;
