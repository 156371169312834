import moment from 'moment';
import { Flex, Icon, Image, Spinner } from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { createColumnHelper } from '@tanstack/table-core';
import { useNavigate } from 'react-router-dom';
import { fetchInvoicesForProfessionalForClient } from '../../services/InvoiceService';
import { auth } from '../../config/firebase';
import { CurrentProfessionalContextType, CurrentProfessionalContext } from '../../contexts/CurrentProfessionalProvider';
import { Invoice } from '../../models/Invoice';
import { DataTable } from '../DataTable';

type InvoiceTableProps = {
  clientuserid: string;
};

function InvoiceTable({ clientuserid, ...rest }: InvoiceTableProps) {
  const { currentProfessional }: CurrentProfessionalContextType = useContext(CurrentProfessionalContext);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  useEffect(() => {
    if (!user) return;
    if (!currentProfessional) return;
    const fetch = async () => {
      setLoading(true);
      await fetchInvoicesForProfessionalForClient(user, currentProfessional.userid, clientuserid)
        .then((res) => res.json())
        .then((result: Invoice[]) => {
          if (!result || result.length === 0) return;
          setInvoices(result);
          setLoading(false);
        });
    };
    fetch().catch(console.error);
  }, [clientuserid, currentProfessional, user]);

  if (loading) return <Spinner />;

  type invoicesDisplayData = {
    status: string;
    invoicedate: string;
    amount: number;
    hours: number;
    id: number;
  };

  const tabledata: invoicesDisplayData[] = invoices.map((i) => ({
    status: i.status as string,
    invoicedate: moment(i.invoicedate).format('MMM DD, YYYY'),
    amount: i.servicesSubTotal as number,
    hours: (i.totalhours as number).toFixed(1) as unknown as number,
    id: i.id,
  }));

  const columnHelper = createColumnHelper<invoicesDisplayData>();

  const columns = [
    columnHelper.accessor('id', {
      cell: (info: any) => info.getValue(),
      header: 'Invoice #',
      meta: {
        isNumeric: true,
      },
    }),
    columnHelper.accessor('status', {
      cell: (info: any) => info.getValue(),
      header: 'Status',
    }),
    columnHelper.accessor('invoicedate', {
      cell: (info: any) => `${info.getValue()}`,
      header: 'Date Billed',
    }),
    columnHelper.accessor('amount', {
      cell: (info: any) => `$${info.getValue()}`,
      header: 'Total Earnings',
      // meta: {
      //   isNumeric: true,
      // },
    }),
    columnHelper.accessor('hours', {
      cell: (info: any) => info.getValue(),
      header: 'Billed Hours',
      meta: {
        isNumeric: true,
      },
    }),
  ];

  const rowSelectedHandler = (index: number) => {
    const rowid = tabledata[index].id;
    const inv = invoices.filter((i) => i.id === rowid)[0];
    navigate('/invoice', { state: { clientuserid, invoiceid: inv.id } });
  };

  return (
    <Flex flexDir="column" flex={1} mr={3} height="100%" mt={4}>
      <DataTable columns={columns} data={tabledata} shadeAlternatingRows={false} handleRowSelected={rowSelectedHandler} />
    </Flex>
  );
}

export default InvoiceTable;
