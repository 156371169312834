/* eslint-disable react/no-array-index-key */
import { Flex } from '@chakra-ui/react';
import React from 'react';

type PageTabsProps = {
  tabNames: string[];
  selectedTab: string;
  handleTabSelected: (tab: string) => void;
};

export function PageTabs({ tabNames, selectedTab, handleTabSelected }: PageTabsProps) {
  return (
    <Flex flex={1} width="100%" fontWeight="bold">
      {tabNames.map((tab, i) => (
        <Flex
          key={i.toString()}
          flex={1}
          px={12}
          py={1}
          cursor="pointer"
          justifyContent="center"
          borderTopLeftRadius={i === 0 ? 'md' : undefined}
          borderTopRightRadius={i === tabNames.length - 1 ? 'md' : undefined}
          borderColor="orange.400"
          borderWidth={1}
          bg={selectedTab === tab ? 'orange.400' : 'white'}
          color={selectedTab === tab ? 'white' : 'orange.400'}
          onClick={() => handleTabSelected(tab)}
        >
          {tab}
        </Flex>
      ))}
    </Flex>
  );
}
