import {
  HStack,
  Text,
  Flex,
  Heading,
  VStack,
  Image,
  InputLeftElement,
  Input,
  InputGroup,
  Box,
  Portal,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Avatar,
} from '@chakra-ui/react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { SearchIcon, HamburgerIcon, WarningTwoIcon } from '@chakra-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import { fetchUserMessages } from '../services/MessagesService';
import { UserMessage } from '../models/Message';
import Notifications from './Notifications';
import { CurrentProfessionalContext, CurrentProfessionalContextType } from '../contexts/CurrentProfessionalProvider';

type HeaderProps = {
  handleHamburgerToggle(): void;
};

function Header({ handleHamburgerToggle }: HeaderProps) {
  const [user, loading, error] = useAuthState(auth);
  const { currentUser } = useContext(CurrentUserContext);
  const { currentProfessional, setCurrentProfessional }: CurrentProfessionalContextType = useContext(CurrentProfessionalContext);
  const navigate = useNavigate();
  const [newMessageCount, setNewMessageCount] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    if (!user) return;
    if (!user.uid) return;
    if (!currentUser) return;
    const fetchMessages = async () => {
      await fetchUserMessages(user)
        .then((res) => res.json())
        .then((result: UserMessage[]) => {
          const newmsgcount = result.filter((m) => m.hasUnreadThreads).length;
          console.log('Header.fetched UserMessages');
          setNewMessageCount(newmsgcount);
        });
    };
    fetchMessages().catch(console.error);
  }, [user, currentUser]);

  return (
    <>
      <Flex flex={1} direction="row" bg="blue.400" justifyContent="space-between" p={6} width="100%">
        <HStack>
          <HamburgerIcon boxSize={10} color="orange.400" mr={4} onClick={handleHamburgerToggle} />
          <Heading color="orange.400" onClick={() => navigate('/')}>
            donne
          </Heading>
        </HStack>
        <HStack mr={10}>
          {/* <InputGroup bg="white" mx={8} borderRadius="3xl">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="charcoal" />
            </InputLeftElement>
            <Input placeholder="Search..." borderRadius="3xl" />
          </InputGroup> */}
          <VStack px={2} onClick={() => setShowNotifications(!showNotifications)} cursor="pointer">
            <Image src="/icons/notifications.png" alt="menu" flex={1} boxSize="2rem" />
            <Text fontSize="xs" color="charcoal">
              NOTIFICATIONS
            </Text>
          </VStack>
          <VStack px={2} onClick={() => navigate('/messages')} cursor="pointer">
            <Image src="/icons/messages.png" alt="menu" flex={1} boxSize="2rem" />
            {newMessageCount > 0 && (
              <Box color="black" bg="red.500" borderRadius="full" px={1.5} position="absolute" transform="translateY(-10px) translateX(8px)">
                {newMessageCount}
              </Box>
            )}
            <Text fontSize="xs" color="charcoal">
              MESSAGES
            </Text>
          </VStack>
          <VStack px={2} onClick={() => navigate('/account')} cursor="pointer">
            {/* <Image src="/icons/profile.png" alt="menu" flex={1} boxSize="2rem" /> */}
            <Avatar src={currentUser.useravatarurl} size="sm" />
            {currentProfessional && currentProfessional.onboardingRequirements?.profileRequired && (
              <Box px={1.5} position="absolute" transform="translateY(-10px) translateX(22px)">
                <WarningTwoIcon w={4} h={4} color="orange.400" />
              </Box>
            )}
            <Text fontSize="xs" color="charcoal">
              PROFILE
            </Text>
          </VStack>
        </HStack>
      </Flex>
      <Drawer isOpen={showNotifications} size="sm" placement="right" onClose={() => setShowNotifications(false)}>
        {/* <DrawerOverlay /> */}
        <DrawerContent>
          <DrawerCloseButton color="orange.400" />
          <DrawerHeader>Notifications</DrawerHeader>
          <DrawerBody>
            <Notifications />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Header;
