/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
import { Box, Text, Flex, Button, Textarea, Input, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useFilePicker } from 'use-file-picker';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { EditIcon } from '@chakra-ui/icons';
import { MultiSelect } from 'chakra-multiselect';
import { useLocation } from 'react-router-dom';
import { User } from '../../models/User';
import { auth } from '../../config/firebase';
import { CurrentUserContext } from '../../contexts/CurrentUserProvider';
import { CurrentProfessionalContext } from '../../contexts/CurrentProfessionalProvider';
import LoadingScreen from '../../components/LoadingScreen';
import { PortfolioLink, ProfessionalUser, ServiceDetails } from '../../models/ProfessionalUser';
import { updateUser } from '../../services/UsersService';
import { updateProfessionalDetails, updateProfessionalDetailsWithFile } from '../../services/ProfessionalsService';
import UserMediaAssetDisplay from '../../components/UserMediaAssetDisplay';
import { STYLES_LIST } from '../../models/StylesList';
import ProfileActivationControl from '../../components/ProfileActivationControl';
import colors from '../../theme/foundations/colors';
import EditableProfileAvatar from './EditableProfileAvatar';
import ProfessionalRatingSummary from './ProfessionalRatingSummary';
import SocialAccounts from './SocialAccounts';

type AccountProfileProps = {
  userdata: User;
  setUserData: React.Dispatch<React.SetStateAction<User | undefined>>;
  prouser: ProfessionalUser;
  setprouser: React.Dispatch<React.SetStateAction<ProfessionalUser | undefined>>;
  updateUserDataFieldHandler: (field: string, value: any) => void;
  autoSaveUserDataFieldHandler: (field: string, value: any) => void;
  saveUserDataHandler: () => void;
  updateProDataFieldHandler: (field: string, value: any) => void;
  autoSaveProDataFieldHandler: (field: string, value: any) => void;
  saveProDataHandler: () => void;
  updateUserAvatarUrl: (url: string) => void;
};

function AccountProfile({
  userdata,
  setUserData,
  prouser,
  setprouser,
  updateUserDataFieldHandler,
  autoSaveUserDataFieldHandler,
  saveUserDataHandler,
  updateProDataFieldHandler,
  autoSaveProDataFieldHandler,
  saveProDataHandler,
  updateUserAvatarUrl,
}: AccountProfileProps) {
  const [user] = useAuthState(auth);
  const { currentProfessional, setCurrentProfessional } = useContext(CurrentProfessionalContext);

  console.log('prouser: ', prouser);

  const [openFileSelector, { filesContent, plainFiles, loading, errors, clear: clearFilePicker }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*', '.png', '.jpg', 'jpeg'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 20,
    imageSizeRestrictions: {
      maxHeight: 2000, // in pixels
      maxWidth: 3000,
      minHeight: 20,
      minWidth: 20,
    },
    onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
      if (plainFiles[0]) {
        updateProfessionalDetailsWithFile(user as UserAuth, prouser as ProfessionalUser, plainFiles[0])
          .then((res: any) => res.json())
          .then((result) => {
            updateProDataFieldHandler('profile_background_image', result.profile_background_image);
          })
          .then(() => clearFilePicker())
          .catch((err) => console.log(err));
      }
    },
  });

  const handleSelectCoverPhoto = () => {
    clearFilePicker();
    openFileSelector();
  };

  // if (!currentProfessional || !currentUser) return <LoadingScreen />;
  if (!prouser || !userdata) return <LoadingScreen />;
  // const prouser = currentProfessional as ProfessionalUser;

  // This page needs to support editing fields that belong to both the core user entity and the professionaldetails entity.
  // Be careful because the ProfessionalUser model has fields from the User entity that should not be edited from the ProfDetail entity

  const renderSocialIconsSection = () => <SocialAccounts postSaveHandler={setprouser} />;

  return (
    <Flex flex={1} width="100%" p={12}>
      <Flex flex={0.2} flexDir="column" alignItems="center" mx={4}>
        <EditableProfileAvatar currentAvatarUrl={userdata.useravatarurl} handleAvatarChanged={updateUserAvatarUrl} />
        <ProfessionalRatingSummary rating={prouser.rating} reviews={prouser.reviewdetails} />
      </Flex>
      <Flex flex={0.8} flexDir="column" mx={4}>
        <Flex flex={1} justifyContent="space-between">
          <Text fontSize="18" fontWeight="bold" mb={2} flex={1}>
            Profile
          </Text>
          <ProfileActivationControl
            alignItems="flex-end"
            togglevalue={prouser.visible_to_clients}
            handleOnChange={updateProDataFieldHandler}
            handleAutoSave={autoSaveProDataFieldHandler}
            userdata={userdata}
            prouser={prouser}
          />
        </Flex>
        <Flex flexDir="column" flex={1}>
          <Flex flex={1} my={1}>
            <Flex flex={2} flexDir="column" mr={4}>
              <Text fontSize="xs">Profile Tagline / Title *</Text>
              <Input
                value={prouser.tagline}
                onChange={(e) => updateProDataFieldHandler('tagline', e.target.value)}
                onBlur={(e) => autoSaveProDataFieldHandler('tagline', e.target.value)}
              />
            </Flex>
            <Flex flex={1} flexDir="column" mr={4}>
              <Text fontSize="xs">Years of Experience</Text>
              <Input
                type="number"
                value={prouser.years_of_experience}
                onChange={(e) => updateProDataFieldHandler('years_of_experience', e.target.valueAsNumber as unknown as number)}
                onBlur={(e) => autoSaveProDataFieldHandler('years_of_experience', e.target.value as unknown as number)}
              />
            </Flex>
            <Flex flex={2} flexDir="column" mr={4}>
              <Text fontSize="xs">Username</Text>
              <Input
                value={userdata.username}
                onChange={(e) => updateUserDataFieldHandler('username', e.target.value)}
                onBlur={(e) => autoSaveUserDataFieldHandler('username', e.target.value)}
              />
            </Flex>
          </Flex>
          <Flex flex={1} my={2}>
            <Flex flex={1} flexDir="column" mr={4}>
              <Text fontSize="xs">Languages spoken</Text>
              <Input
                value={prouser.languages?.join(', ')}
                onChange={(e) => {
                  let newarray = e.target.value.split(',');
                  newarray = newarray.map((s) => s.trim());
                  updateProDataFieldHandler('languages', newarray);
                }}
                onBlur={(e) => {
                  let newarray = e.target.value.split(',');
                  newarray = newarray.map((s) => s.trim());
                  autoSaveProDataFieldHandler('languages', newarray);
                }}
              />
            </Flex>
            <Flex flex={1} flexDir="column" mr={2}>
              <Text fontSize="xs">Styles</Text>
              <MultiSelect
                multiple
                options={STYLES_LIST.map((lbl) => ({ label: lbl, value: lbl }))}
                value={prouser?.styles || undefined}
                onChange={(e) => {
                  updateProDataFieldHandler('styles', e as string[]);
                  autoSaveProDataFieldHandler('styles', e as string[]);
                }}
                // onBlur={(e) => autoSaveProDataFieldHandler('styles', e as unknown as string[])}
              />
            </Flex>
          </Flex>
          <Flex flex={1} my={2}>
            <Flex flex={1} flexDir="column" mr={2}>
              <Text fontSize="xs">Profile Short bio: *</Text>
              <Textarea
                p={2}
                maxLength={599}
                value={prouser.bio}
                onChange={(e) => updateProDataFieldHandler('bio', e.target.value)}
                onBlur={(e) => autoSaveProDataFieldHandler('bio', e.target.value)}
              />
            </Flex>
          </Flex>
          <Flex flex={1} my={4} width="50%">
            <Flex flex={1} flexDir="column" alignItems="flex-start">
              <Flex mb={4} flex={1}>
                <Text fontSize="xs" mr={4}>
                  Public Cover Photo
                </Text>
                <EditIcon color="orange.400" onClick={() => handleSelectCoverPhoto()} />
                {errors && errors.length > 0 && <Text>Error: file size too large.</Text>}
              </Flex>
              {/* <Image src={filesContent && filesContent[0] ? filesContent[0].content : prouser.profile_background_image} maxHeight={64} fit="contain" /> */}
              <UserMediaAssetDisplay
                uri={filesContent && filesContent[0] ? filesContent[0].content : (prouser.profile_background_image as string)}
                // uri={prouser.profile_background_image as string}
                type="image"
                height={64}
                width={64}
              />
            </Flex>
          </Flex>
          <Flex flex={1} my={1} width="50%">
            <Flex flex={1} flexDir="column" alignItems="flex-start">
              <Flex flex={1} justifyContent="space-between" width="80%" mb={4} alignItems="center">
                <Text fontWeight="bold">Social Accounts</Text>
              </Flex>
              {renderSocialIconsSection()}
            </Flex>
          </Flex>
          <Flex mt={4} width="90%" justifyContent="flex-end">
            {/* <Button variant="darkoutline" onClick={() => setprouser(currentProfessional)}>
              Cancel
            </Button> */}
            <Button
              variant="solid"
              onClick={() => {
                saveProDataHandler();
                saveUserDataHandler();
              }}
            >
              Save Profile
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default AccountProfile;
