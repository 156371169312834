/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Text, Flex, Select, HStack, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, DrawerCloseButton } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { Rating } from 'react-simple-star-rating';
import ProfessionalReviewCard from './ProfessionalReviewCard';
import { ProfessionalReview } from '../../models/ProfessionalReview';

type ProfessionalRatingSummaryProps = {
  rating: number;
  reviews: ProfessionalReview[];
};

function ProfessionalRatingSummary({ rating, reviews }: ProfessionalRatingSummaryProps) {
  const [showReviewDetails, setShowReviewDetails] = useState(false);
  const [sortedReviews, setSortedReviews] = useState(reviews);

  const handleSort = (sortval: string) => {
    const clone = [...sortedReviews];
    const sorted = clone.sort((a, b) => {
      if (a && b && sortval === 'datedesc' && a.reviewdate && b.reviewdate) return b.reviewdate.valueOf() > a.reviewdate.valueOf() ? 1 : -1;
      if (a && b && sortval === 'dateasc' && a.reviewdate && b.reviewdate) return a.reviewdate.valueOf() > b.reviewdate.valueOf() ? 1 : -1;
      if (a && b && sortval === 'ratingdesc') return a.rating < b.rating ? 1 : -1;
      if (a && b && sortval === 'ratingasc') return a.rating > b.rating ? 1 : -1;
      return 0;
    });
    setSortedReviews(sorted);
  };

  return (
    <Flex flexDir="column">
      <Text fontWeight="bold" textAlign="center">
        Rating
      </Text>
      <Flex flexDir="column" justifyContent="center">
        {!rating || (rating === 0.0 && <Text>No Reviews Yet</Text>)}
        <Rating initialValue={rating} allowFraction SVGstyle={{ display: 'inline-block' }} size={20} readonly />
        {reviews && reviews.length > 0 && (
          <Text as="u" onClick={() => setShowReviewDetails(true)}>
            See all Reviews
          </Text>
        )}
      </Flex>
      <Drawer placement="right" size="lg" onClose={() => setShowReviewDetails(false)} isOpen={showReviewDetails}>
        <DrawerOverlay />
        <DrawerContent bg="white">
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            <Text variant="header" color="charcoal">
              See All Reviews
            </Text>
          </DrawerHeader>
          <DrawerBody px={12}>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex flexDir="column" my={2} flex={1}>
                <HStack alignItems="center" my={1}>
                  <StarIcon color="blue.400" boxSize="8" />
                  <Text fontSize="xl" fontWeight="bold" color="blue.400">
                    {rating}
                  </Text>
                </HStack>
                <Text mt={1} color="blue.400">
                  {reviews ? reviews.length : 0} reviews
                </Text>
              </Flex>
              <Select
                variant="outline"
                placeholder="Sort..."
                flex={1}
                mx={1}
                bg="white"
                color="charcoal"
                borderColor="charcoal"
                fontWeight="bold"
                onChange={(e) => handleSort(e.target.value)}
              >
                <option value="datedesc">Newest</option>
                <option value="dateasc">Oldest</option>
                <option value="ratingdesc">Highest</option>
                <option value="ratingasc">Lowest</option>
              </Select>
            </Flex>
            <Flex flexDir="column">
              {sortedReviews &&
                sortedReviews.map((review) => (
                  <Flex key={review.id.toString()} mb={6}>
                    <ProfessionalReviewCard review={review} />
                  </Flex>
                ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

export default ProfessionalRatingSummary;
