/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
import { Text, Flex, Button, Input, useToast, Spinner, Link } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import moment from 'moment';
import { User } from '../../models/User';
import { auth } from '../../config/firebase';
import { CurrentUserContext } from '../../contexts/CurrentUserProvider';
import { CurrentProfessionalContext } from '../../contexts/CurrentProfessionalProvider';
import LoadingScreen from '../../components/LoadingScreen';
import { ProfessionalUser } from '../../models/ProfessionalUser';
import { updateUser } from '../../services/UsersService';
import EditableProfileAvatar from './EditableProfileAvatar';
import ProfessionalRatingSummary from './ProfessionalRatingSummary';
import { ConnectedAccount } from '../../models/PaymentsModels';
import { createConnectedUserAccount } from '../../services/PaymentsService';

type PersonalInfoProps = {
  userdata: User;
  setUserData: React.Dispatch<React.SetStateAction<User | undefined>>;
  updateUserDataFieldHandler: (field: string, value: any) => void;
  autoSaveUserDataFieldHandler: (field: string, value: any) => void;
  saveUserDataHandler: () => void;
  updateUserAvatarUrl: (url: string) => void;
};

function PersonalInfo({
  userdata,
  setUserData,
  updateUserDataFieldHandler,
  autoSaveUserDataFieldHandler,
  saveUserDataHandler,
  updateUserAvatarUrl,
}: PersonalInfoProps) {
  const { currentUser } = useContext(CurrentUserContext);
  const [user] = useAuthState(auth);
  const { currentProfessional } = useContext(CurrentProfessionalContext);
  const [prouser, setprouser] = useState<ProfessionalUser>(currentProfessional as ProfessionalUser);
  const [showSpinner, setShowSpinner] = useState(false);
  const [connectedAccount, setConnectedAccount] = useState<ConnectedAccount | undefined>((currentProfessional as ProfessionalUser).connectedAccount);

  useEffect(() => {
    if (!currentProfessional) return;
    if (prouser) return;
    setprouser(currentProfessional);
  }, [currentProfessional]);

  // TODO: consider a useEffect where, if connectedAccount.status === pending_onboarding, delay a few seconds then fetch connectedaccount and update it

  if (!currentProfessional || !currentUser) return <LoadingScreen />;
  if (!prouser || !userdata) return <LoadingScreen />;
  // const prouser = currentProfessional as ProfessionalUser;

  // This page needs to support editing fields that belong to both the core user entity and the professionaldetails entity.
  // Be careful because the ProfessionalUser model has fields from the User entity that should not be edited from the ProfDetail entity

  const handleCreateConnectedAccount = async () => {
    // https://stripe.com/docs/connect/express-accounts
    // https://stripe.com/docs/connect/collect-then-transfer-guide?platform=react-native&ui=payment-sheet#create-account
    setShowSpinner(true);
    await createConnectedUserAccount(user as UserAuth)
      .then((res: any) => res.json())
      .then((result: ConnectedAccount) => {
        console.log('ConnectedAccount result: ', result);
        setConnectedAccount(result);
        window.location.replace(result.accountLink);
      })
      .catch((err) => console.log(err));
    // .finally(() => setShowSpinner(false));
  };

  if (showSpinner) return <Spinner />;

  return (
    <Flex flex={1} width="100%" p={12}>
      <Flex flex={0.2} flexDir="column" alignItems="center" mr={6}>
        <EditableProfileAvatar currentAvatarUrl={userdata.useravatarurl} handleAvatarChanged={updateUserAvatarUrl} />
        <ProfessionalRatingSummary rating={prouser.rating} reviews={prouser.reviewdetails} />
      </Flex>
      <Flex flex={0.8} flexDir="column" mx={4}>
        <Text fontSize="18" fontWeight="bold" mb={2}>
          Personal Info
        </Text>
        <Flex flexDir="column" flex={1}>
          <Flex flex={1} my={2}>
            <Flex flex={1} flexDir="column" mr={4}>
              <Text>Email*</Text>
              <Input
                value={userdata.email}
                onBlur={(e) => autoSaveUserDataFieldHandler('email', e.target.value)}
                onChange={(e) => updateUserDataFieldHandler('email', e.target.value)}
              />
            </Flex>
            <Flex flex={1} flexDir="column" mr={2}>
              <Text>Phone</Text>
              <Input
                value={userdata.phone}
                onBlur={(e) => autoSaveUserDataFieldHandler('phone', e.target.value)}
                onChange={(e) => updateUserDataFieldHandler('phone', e.target.value)}
              />
            </Flex>
          </Flex>
          <Flex flex={1} my={2}>
            <Flex flex={1} flexDir="column" mr={4}>
              <Text>Address*</Text>
              <Input
                name="street-address"
                autoComplete="street-address"
                value={userdata.address?.line1}
                onBlur={(e) => autoSaveUserDataFieldHandler('address.line1', e.target.value)}
                onChange={(e) => updateUserDataFieldHandler('address.line1', e.target.value)}
              />
            </Flex>
            <Flex flex={1} flexDir="column" mr={2}>
              <Text>Address Line 2</Text>
              <Input
                autoComplete="address-line2"
                value={userdata.address?.line2}
                onBlur={(e) => autoSaveUserDataFieldHandler('address.line2', e.target.value)}
                onChange={(e) => updateUserDataFieldHandler('address.line2', e.target.value)}
              />
              {/* <Input
                autoComplete="address-line2"
                value={userdata.address?.line2}
                onChange={(e) => setUserData({ ...userdata, 'address.line2': e.target.value } as User)}
              /> */}
            </Flex>
          </Flex>
          <Flex flex={1} my={2}>
            <Flex flex={1} flexDir="column" mr={4}>
              <Text>City*</Text>
              <Input
                name="city"
                autoComplete="address-level2"
                value={userdata.address?.city}
                onBlur={(e) => autoSaveUserDataFieldHandler('address.city', e.target.value)}
                onChange={(e) => updateUserDataFieldHandler('address.city', e.target.value)}
              />
            </Flex>
            <Flex flex={1} flexDir="row" mr={2}>
              <Flex flex={1} flexDir="column" mr={2}>
                <Text>State*</Text>
                <Input
                  autoComplete="address-level1"
                  value={userdata.address?.stateCode}
                  onBlur={(e) => autoSaveUserDataFieldHandler('address.stateCode', e.target.value)}
                  onChange={(e) => updateUserDataFieldHandler('address.stateCode', e.target.value)}
                />
              </Flex>
              <Flex flex={1} flexDir="column">
                <Text>Zipcode*</Text>
                <Input
                  name="postal-code"
                  autoComplete="postal-code"
                  value={userdata.address?.zipCode}
                  onBlur={(e) => autoSaveUserDataFieldHandler('address.zipCode', e.target.value)}
                  onChange={(e) => updateUserDataFieldHandler('address.zipCode', e.target.value)}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex flex={1} my={2} flexDir="row" mr={2}>
            <Flex flex={1} flexDir="column" mr={4}>
              <Text>Date of Birth</Text>
              <Flex flexDir="row" alignItems="center">
                <Input
                  type="date"
                  defaultValue={userdata?.birthday ? moment(userdata.birthday).format('YYYY-MM-DD') : undefined}
                  onChange={(event) => {
                    const date = event.target.value === '' ? null : moment(event.target.value, 'YYYY-MM-DD').toDate();
                    updateUserDataFieldHandler('birthday', date);
                  }}
                  onBlur={(event) => {
                    const date = event.target.value === '' ? null : moment(event.target.value, 'YYYY-MM-DD').toDate();
                    autoSaveUserDataFieldHandler('birthday', date);
                  }}
                />
              </Flex>
            </Flex>
            <Flex flex={1} flexDir="column">
              <Text>Pronouns</Text>
              <Input
                name="pronouns"
                value={userdata.preferredPronouns}
                onBlur={(e) => autoSaveUserDataFieldHandler('preferredPronouns', e.target.value)}
                onChange={(e) => updateUserDataFieldHandler('preferredPronouns', e.target.value)}
              />
            </Flex>
          </Flex>
          <Flex my={8} flexDir="column">
            <Text fontSize="18" fontWeight="bold" mb={2}>
              Connect / View Payment Account
            </Text>
            <Text>Donne partners with Stripe, an industry leader, to manage payments.</Text>
            {!connectedAccount && (
              <>
                <Text>
                  Setting up your Stripe account will take 5-10 minutes and requires that you have access to your bank account info. Click the button below to
                  get started. You must do this before you can get paid.
                </Text>
                <Button variant="solid" maxWidth="50%" px={1} mt={2} onClick={handleCreateConnectedAccount}>
                  Setup Your Stripe Account
                </Button>
              </>
            )}
            {connectedAccount && connectedAccount.status === 'pending_onboarding' && (
              <Text px={1} mt={2}>
                Your Stripe account is pending completion of onboarding. This should only take a few minutes.
              </Text>
            )}
            {connectedAccount && connectedAccount.status === 'details_submitted' && (
              <Link
                isExternal
                maxWidth="50%"
                color="white"
                bg="blue.400"
                px={4}
                py={1}
                textAlign="center"
                borderRadius="xl"
                href="https://connect.stripe.com/express_login"
              >
                View Your Stripe Account
              </Link>
            )}
          </Flex>
          <Flex mt={4} width="90%" justifyContent="flex-end">
            {/* <Button variant="darkoutline" onClick={() => setUserData(currentUser)}>
              Cancel
            </Button> */}
            <Button variant="solid" onClick={saveUserDataHandler}>
              Save Personal Info
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default PersonalInfo;
